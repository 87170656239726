import React, { useContext, useEffect, useRef, useState } from "react";
import { DatePicker, Menu } from "antd";
import { DashboardContainer } from "../Dashboard";
import "./messages.less";
import { MessageCard, InputText, InputButton } from "components/Global";
import { SentIcon } from "components/Icons";
import { getMessage, getSession, saveMessageReaded } from "../../services/message";
import {AxiosError, AxiosResponse} from "axios";
import { useLocation } from "react-router-dom";
import { AuthContext } from "components/contexts/UserContext";
import moment from "moment";
import { NotificationContext } from "components/contexts/NotificationContextProvider";
import {MessageFilled, MessageOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
const URL = process.env.REACT_APP_SOCKET_BASE_URL;
export interface MESSAGE {
  key: number;
  username?: string;
  message?: string;
  messages?: string;
  response?: string | null;
  time?: string;
  is_admin: boolean;
}

const Messages: React.FC = () => {
  const [listConversations, setListConversation] = useState<any>([]);
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<MESSAGE[]>([]);
  const [urlString, setUrlString] = useState("");
  const [webSocket, setWebSocket] = useState<any>(null);
  const [user, setUser] = useState<any>(null);
  const [keyMessage, setKeyMessage] = useState<string>("");
  const location = useLocation();
  const { state } = useContext(AuthContext);
  const [filterDate, setFilterDate] = useState<[moment.Moment, moment.Moment]>([
    moment(),
    moment().add(1, "week"),
  ]);
  const { RangePicker } = DatePicker;
  const divMessage = useRef<HTMLDivElement>(null);

  const { notifications } = useContext(NotificationContext);
  const loadData = (id: string, conversation_code: string) => {
    setUrlString(`${URL}/chat/?${conversation_code}`);
    setMessages([]);
    getMessage(id).then((data: AxiosResponse) => {
      const res = data.data.map((items: any) => ({
        key: items.timestamp + items.username,
        time: items.timestamp,
        message: items.message,
        username: items.username,
        response: items.intent?.responses[0].message,
        is_admin: items.is_admin,
      }));
      res.sort((a: any, b: any) => {
        return new Date(a.time).getTime() - new Date(b.time).getTime();
      });
      console.log("liste messages ::=>", res);
      setMessages(res);
    });
  };

  const LoadSession = () => {
    const currentDate = new Date();
    getSession()
      .then((data: AxiosResponse) => {
        data.data.sort((a: any, b: any) => {
            return new Date(b.date_created).getTime() - new Date(a.date_created).getTime();
        });
        console.log("liste messages by session ::=>",currentDate.getUTCSeconds(), data);
        setListConversation(data.data);
      })
      .catch(() => {
        console.log("error session");
      });
  }
  useEffect(() => {
    LoadSession();
  }, [notifications]);
  useEffect(() => {
    if (location?.state?.id) {
      loadData(location.state.id, location.state.conversation_code);
      setKeyMessage(location.state?.id as string);
    }
  }, [location?.state?.id]);

  useEffect(() => {
    console.log("urlString", urlString);
    urlString && setWebSocket(new WebSocket(urlString));
  }, [urlString]);

  useEffect(() => {
    console.log('my best ::=>',notifications);
    const contentMessage = "A new object has been created.";
    const filteredData = notifications.filter(
      (notification) =>
        !listConversations.some(
          (conversation: any) =>
            conversation.conversation_code === notification.conversation_code ||
            notification.message.toLowerCase() !== contentMessage.toLowerCase()
        )
    );
    const mapData = filteredData?.map((data: any) => ({
      ...data,
      conversation_username: data?.username,
    }));
    console.log("messages by notifications :: ,", mapData);
    setListConversation((prev: any) => [...mapData, ...prev]);
  }, [notifications]);
  useEffect(() => {
    if (webSocket) {
      webSocket.onopen = () => {
        console.log("connected", urlString);
      };
      webSocket.onclose = () => {
        console.log("disconnected");
      };
      webSocket.onmessage = (event: any) => {
        const response = JSON.parse(event.data);
        console.log('not :::::==>',response);

        setMessages((prev) => [...prev, response as MESSAGE]);
      };
      webSocket.onerror = (event: any) => {
        console.log("error", event);
      };
    }
    return () => {
      if (webSocket) {
        webSocket.close();
      }
    };
  }, [webSocket]);

  useEffect(() => {
    divMessage.current?.lastElementChild?.scrollIntoView({
      behavior: "smooth",
    });
  }, [messages]);
  const sendMessage = () => {
    console.log("message", message);
    if (message.trim()) {
      const messageObj = {
        username: state.user?.username,
        message,
        time: new Date().toISOString(),
      };
      webSocket.send(JSON.stringify(messageObj));
      setMessage("");
    }
  };

  return (
    <DashboardContainer>
      <div className="page-message">
        <h1 className="header-page">Conversations List</h1>
        <div className="container-chat-page">
          <div className="container-list-chat">
            <div className="header-filter">
              <label htmlFor="filter" className="filter-label">
                Filter
              </label>
              <RangePicker
                showTime
                allowClear={false}
                value={filterDate}
                onChange={(values) => {
                  values &&
                    setFilterDate([
                      values[0] as moment.Moment,
                      values[1] as moment.Moment,
                    ]);
                }}
              />
            </div>
            <Menu
              className="menu-list-chat"
              items={listConversations?.map((item: any) => {
                return {
                  key: item.key,
                  label: (
                    <MessageCard
                      avatar={item.conversation_username}
                       className= {item.message_waiting? "new-message" : ""}
                      title={
                        <div className="container-conversations">
                          <span className="conversation-title">
                            {item.conversation_username}
                            {item.message_waiting && (
                                <span className="message-waiting">
                                    <MessageFilled style={{color: "red"}} twoToneColor={"#00ffff"}/>
                                </span>
                            )}
                          </span>
                          <span className="conversation-email">
                            {item.conversation_email}
                          </span>
                        </div>
                      }
                      onclick={() => {
                        loadData(item.id, item.conversation_code);
                        setUser(item);
                        console.log("cliiiiiiiiiiiiiiiiiiiiiiiiiiiiikeed ::><", item);
                        saveMessageReaded(item.conversation_code).then((response: AxiosResponse) => {
                          console.log("response save message readed",response);
                          LoadSession();
                        }).catch((error: AxiosError) => {
                          console.log("error save message readed",error);
                        })
                      }}
                    />
                  ),
                };
              })}
              onSelect={(e) => {
                setKeyMessage(e.key);
              }}
              selectedKeys={[keyMessage]}
            />
          </div>
          <div className="container-main-chat">
            <div className="message-list" ref={divMessage}>
              {messages.slice(0).map((item, index) =>
                item.response ? (
                  <React.Fragment key={item.key}>
                    <MessageCard
                      time={item.time}
                      title={item.username}
                      description={item.message}
                      theme="secondary"
                    />
                    <MessageCard
                      time={item.time}
                      title="bot"
                      description={item.response}
                      theme="primary"
                    />
                  </React.Fragment>
                ) : (
                  <MessageCard
                    key={item.key}
                    time={item.time}
                    title={item.is_admin ? state.user?.username : item.username}
                    description={item.message}
                    theme={item.is_admin ? "primary" : "secondary"}
                  />
                )
              )}
            </div>
            {urlString && (
              <form
                className="container-input-chat"
                onSubmit={(e) => {
                  e.preventDefault();
                  urlString && sendMessage();
                }}
              >
                <InputText
                  theme="secondary"
                  placeholder={"Type a message ..."}
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
                <InputButton
                  type="primary"
                  htmlType="submit"
                  disabled={!urlString}
                >
                  <SentIcon />
                </InputButton>
              </form>
            )}
          </div>
          <div className="container-details">
            <h2>Chat Details</h2>
            {user && (
              <ul>
                <li>
                  <div>Username </div>
                  <div>{user?.conversation_username}</div>
                </li>
                <li>
                  <div>Email </div>
                  <div>{user?.conversation_email}</div>
                </li>
                <li>
                  <div>First Chat </div>
                  <div> 
                    {moment(user?.date_created).format("YYYY-MM-DD hh:mm")}
                  </div>
                </li>
                <li>
                  <div>Last Chat </div>
                  <div>
                    {moment(messages[messages.length - 1]?.time).format(
                      "YYYY-MM-DD hh:mm"
                    )}
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </DashboardContainer>
  );
};

export default Messages;
