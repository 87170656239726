import React from 'react'

export default function MessageIcon() {
  return (
        <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg" className='aion-message-icon'>
            <path d="M10.0699 6.22031L2.08997 0.0703125H18.0699L10.0699 6.22031Z" fill="#183443"/>
            <path d="M7.29999 5.70047L0.849976 10.9705V0.730469L7.29999 5.70047Z" fill="#183443"/>
            <path d="M8.35004 6.51044L10.07 7.83044L11.88 6.44043L18.19 11.6704H2.01001L8.35004 6.51044Z" fill="#183443"/>
            <path d="M12.92 5.64047L19.32 0.730469V10.9505L12.92 5.64047Z" fill="#183443"/>
        </svg>
  )
}
