import React, {
  createContext,
  useState,
  PropsWithChildren,
  useEffect,
} from "react";

import {TypeContextCollapsedMenu} from "interface/services"
export const CollapsedMenuContext = createContext<TypeContextCollapsedMenu>({
  isMenuCollapsed: false,
  setIsMenuCollapsed: null,
});

const NotificationContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [isMenuCollapsed, setIsMenuCollapsed] = useState<boolean>(
    Boolean(localStorage.getItem("isMenuCollapsed")) ?? false
  );
  useEffect(() => {
    localStorage.setItem("isMenuCollapsed", isMenuCollapsed.toString());
  }, [isMenuCollapsed]);
  return (
    <CollapsedMenuContext.Provider
      value={{ isMenuCollapsed, setIsMenuCollapsed }}
    >
      {children}
    </CollapsedMenuContext.Provider>
  );
};
export default NotificationContextProvider;
