import "./pricingCards.less"
import {TypePricingCard} from "interface/components"

const PricingCards: React.FC<TypePricingCard> = ({ cards }) => {
  return (
    <div className={"wrapper-pricing-cards"} >
      {cards.map((card, index) => {
        return (
          <div className={card.disabled?"package disabled":"package"} key={card.title}>
            <div className="name">{card.title}</div>
            <div className="price">{card.price}</div>
            <div className="trial">{card.trial}</div>
            <hr />
            <div className="content-card-description">
              {card.description}
            </div>
            <div className="container-button">
              <button disabled={card.disabled} onClick={() => !card.disabled && card.onclick(card.id)}>Buy Now</button>
              </div>
          </div>
        );
      })}
    </div>
  );
};
export default PricingCards