import React, { useEffect } from "react";
import { DashboardContainer } from "../Dashboard";
import "./appointments.less";
import { CustomCalendar } from "components/Global";
import moment from "moment";
import { getAppointment } from "services/message";
import { useNavigate } from "react-router-dom";

export interface MESSAGE {
  key: number;
  username?: string;
  message?: string;
  response?: string | null;
  time: string;
  // [INSERT NEW REDUCER KEY ABOVE] < Needed for generating containers seamlessly
}

const AppPlanning: React.FC = () => {
  const [appointments, setAppointments] = React.useState<any[]>([]);
  const navigation = useNavigate()
  useEffect(() => {
    getAppointment().then((appointments: any) => {
      console.log(appointments.data);
      setAppointments(appointments.data);
    });
  }, []);

  const mapDateAppointmentsToCalendar = () =>
    appointments?.map((appointment: any) => {
      const date = moment(appointment?.date_appointment);
      return {
        ...appointment,
        key: appointment?.conversation_code,
        title: `${appointment?.conversation_username} : ${appointment?.conversation_email}`,
        start: date.format(),
        end: date.add(30, "m").format(),
      };
    });
  return (
    <DashboardContainer>
      <div>
        <h1 className="container-header">Planning</h1>
        <div className="container-appointment">
          <CustomCalendar events={mapDateAppointmentsToCalendar()}  eventClick={(arg)=>{
            navigation("/dashboard/messages",{state:{id:arg.event.id,conversation_code:arg.event.extendedProps?.conversation_code}})
          }} />
        </div>
      </div>

    </DashboardContainer>
  );
};

export default AppPlanning;
