import { AoinLoading } from "components/Global";
import { AuthContext } from "components/contexts/UserContext";
import React, { PropsWithChildren, useContext } from "react";
import { Navigate } from "react-router-dom";
import {Urls} from "Constants"
const RequireNotAuth: React.FC<PropsWithChildren> = ({ children }) => {
  const { state } = useContext(AuthContext);
  return state.token ? <Navigate to={Urls.dashboard} replace /> : <>{children}</>;
};

export default RequireNotAuth;
