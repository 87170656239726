
import React from "react";
import { Col, Form, Input } from "antd";
import {TypeTextArea} from "interface/components"
import './textArea.less'
const { Item } = Form;
const { TextArea } = Input;


  const App: React.FC<TypeTextArea> = ({ span = 24,onErrorLabel, theme = "default", label,classNameLabel: classNameLabel="", className ='', ...props}) => {
    const classInput  = ` cls-input-TextArea-${theme} ${className} `
    return (
      <Col span={span} className={`container-text-area ${classNameLabel}`}>
      <Item label={label} >
        <TextArea className={classInput} {...props}  />
        {
            onErrorLabel && (
                <span className="input-label-error" >{onErrorLabel}</span>
              )
          }
      </Item>
      </Col>
    );
  };
  export default App;