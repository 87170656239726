import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import styled from "styled-components";

interface PropsType  {
    label?: string;
    className?: string;
    onChange?:any;
    name?:any;
    formik?:any
    accept?: string;
}
const UploadFile: React.FC<PropsType> = ({ onChange, label,className, name, formik, accept }) => {
    const [selectedFile, setSelectedFile] = useState<File>();
    const [fileName, setFileName] = useState<string | undefined>("Télécharger le logo");

    useEffect(() => {
        // objectUrl = selectedFile ? URL.createObjectURL(selectedFile): undefined;
        if (selectedFile) {
            setFileName(selectedFile.name);
        }
    }, [selectedFile]);

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log("e.target.files", e.target.files)
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            //formik.setFieldValue(name, undefined);
            return;
        }
        setSelectedFile(e.target.files[0]);
        setFileName(e.target?.files[0]?.name);
        formik.setFieldValue(name, e.target.files[0]);
        if (onChange !== undefined) {
            onChange(e);
        }
    };
    return (
        <Container>
            <label htmlFor="" className="lbl-file-upload">
                {label}
            </label>
            <label htmlFor="file-upload" className="cls-upload-file-button">
                <UploadOutlined />
                {fileName}
            </label>
            <input id="file-upload" type="file" size={2} accept={accept} onChange={onFileChange} />
        </Container>
    );
};

export default UploadFile;

const Container = styled.div`
  .lbl-file-upload {
    display: block;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.85);
    padding-top: 10px;
    padding-bottom: 5px;
  }
  .cls-upload-file-button {
    cursor: pointer;
    border: 1px dashed #217aef;
    border-color: #217aef;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 101px;
    color: #217aef;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 21px;
    svg {
      height: 40px;
      width: 40px;
      path {
        fill: #217aef;
      }
    }
  }
  #file-upload {
    display: none;
  }
`;
