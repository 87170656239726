import api from './api';
import { AxiosRequestConfig } from "axios";

api.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        const accessToken = localStorage.getItem('access_token');

        if (accessToken) {
            config.headers = config.headers || {};
            config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const refreshTokenInterceptor = async (error: any) => {
    const originalRequest = error.config;

    if (error.response?.status === 403) {
        originalRequest._retry = true;

        try {
            const refreshToken = localStorage.getItem("refresh_token");

            if (!refreshToken) {
                // Handle the case where there is no refresh token
                // e.g., redirect to login or dispatch a logout action
                return handleLogout();
            }

            const { data } = await api.post('/api/auth/token/refresh/', {
                refresh: refreshToken,
            });

            // Update the access token in the original request headers
            originalRequest.headers = {
                ...originalRequest.headers,
                authorization: `Bearer ${data.access}`,
            };
            localStorage.setItem("access_token", data.access);
            // Retry the original request with the new access token
            return api(originalRequest);
        } catch (refreshError) {
            // Clear tokens from local storage on refresh failure
            handleLogout();

            return Promise.reject(refreshError);
        }
    }

    return Promise.reject(error);
};

const handleLogout = () => {
    // Clear tokens from local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");

    // Redirect to the login page or handle logout as needed
    // Example: navigate('/login');
    // Alternatively, dispatch a logout action if using a global state
    // dispatch({ type: "LOGOUT" });

    // For now, let's simulate a logout by reloading the page
    window.location.reload();

    // Return a rejected promise to stop the original request
    return Promise.reject("User logged out");
};

// Add the interceptors to the Axios instance
api.interceptors.response.use((req)=>{
return req;
} , refreshTokenInterceptor);

export default api;
