import React from "react";

export default function HomeIcon() {
  return (
    <svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg" className="aion-home-icon">
        <path d="M22.77 0.909912C26.93 0.909912 31.09 0.909912 35.25 0.909912C35.27 0.929912 35.28 0.94993 35.3 0.95993C36.84 1.42993 37.5 2.30991 37.5 3.90991C37.5 9.15991 37.5 14.4099 37.5 19.6599C37.5 21.4599 36.37 22.5999 34.59 22.5999C30.85 22.5999 27.12 22.5999 23.38 22.5999C23.09 22.5999 22.8 22.5799 22.52 22.4999C21.25 22.1799 20.47 21.1199 20.47 19.7299C20.47 16.3599 20.47 12.9799 20.47 9.60992C20.47 7.65992 20.47 5.71993 20.47 3.76993C20.47 2.50993 21.12 1.52991 22.23 1.09991C22.4 1.02991 22.58 0.969912 22.77 0.909912Z" fill="white"/>
        <path d="M0.339966 35.8199C0.339966 30.1099 0.339966 24.3999 0.339966 18.6899C0.349966 18.6699 0.369965 18.6499 0.379965 18.6299C0.849965 17.0599 1.73997 16.3899 3.36997 16.3899C7.04997 16.3899 10.72 16.3899 14.4 16.3899C14.71 16.3899 15.03 16.4099 15.33 16.4899C16.59 16.7999 17.37 17.8699 17.37 19.2699C17.37 23.2499 17.37 27.2299 17.37 31.2099C17.37 32.5599 17.38 33.9199 17.37 35.2699C17.35 36.8699 16.21 38.0699 14.65 38.0699C10.78 38.0799 6.90997 38.0799 3.03997 38.0699C1.79997 38.0699 0.779966 37.2699 0.429966 36.0899C0.409966 35.9999 0.369966 35.9099 0.339966 35.8199Z" fill="white"/>
        <path d="M15.07 0.909912C15.52 1.12991 16.02 1.28993 16.41 1.57993C17.09 2.08993 17.37 2.83993 17.37 3.67993C17.38 5.96993 17.39 8.24992 17.37 10.5399C17.36 12.1299 16.21 13.2899 14.61 13.2999C10.78 13.3199 6.93996 13.3099 3.10996 13.2999C1.74996 13.2999 0.739956 12.4799 0.399956 11.1699C0.389956 11.1099 0.359956 11.0599 0.329956 10.9999C0.329956 8.38994 0.329956 5.76991 0.329956 3.15991C0.369956 3.03991 0.419955 2.91992 0.459955 2.78992C0.709955 2.04992 1.16996 1.50993 1.87996 1.17993C2.10996 1.06993 2.34996 0.999912 2.57996 0.909912C6.74996 0.909912 10.91 0.909912 15.07 0.909912Z" fill="white"/>
        <path d="M28.98 38.0698C27.11 38.0698 25.23 38.0698 23.36 38.0698C21.62 38.0698 20.47 36.9298 20.46 35.1898C20.45 32.9798 20.45 30.7699 20.46 28.5499C20.46 26.8299 21.61 25.6799 23.33 25.6799C27.1 25.6699 30.87 25.6699 34.65 25.6799C36.36 25.6799 37.5 26.8399 37.5 28.5599C37.5 30.7699 37.5 32.9799 37.5 35.1999C37.5 36.9199 36.35 38.0698 34.64 38.0698C32.75 38.0798 30.87 38.0698 28.98 38.0698Z" fill="white"/>
    </svg>
  )
}
