import React, { PropsWithChildren, useContext } from "react";
import AppLayout from "../AppLayout";
import { NotificationContext } from "components/contexts/NotificationContextProvider";
import { PageHeader, Menu, Dropdown, Button, Badge } from "antd";
import { useNavigate } from "react-router-dom";
import { NotificationIcon, ProfileIcon } from "components/Icons";
import "./dashboard.less";
import { EmptyNotificationMessage, Urls } from "Constants";
import { AuthContext } from "components/contexts/UserContext";
import { RollbackOutlined } from "@ant-design/icons";
import { FetchSaveNotificationReaded } from "services/message";

const HeadTitle = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Button
        onClick={()=>navigate(-1)}
        icon={<RollbackOutlined />}
        className="round-btn btn-back"
      ></Button>
    </div>
  );
};

const DashboardContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const navigation = useNavigate();
  const { notifications, setNotifications } = useContext(NotificationContext);
  const { handleLogout } = useContext(AuthContext);

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a
              href={Urls.profile}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              My account
            </a>
          ),
        },
        {
          key: "2",
          label: (
            <a
              onClick={(e) => {
                e.preventDefault();
                handleLogout();
                navigation(Urls.login);
              }}
              href="."
            >
              Disconnect
            </a>
          ),
        },
      ]}
    />
  );

  const mapNotifications = () => {
    const menuItems =
      notifications.length === 0
        ? [
            {
              key: "Empty Notifications",
              label: (
                <div className="empty-notifications">
                  {EmptyNotificationMessage}
                </div>
              ),
            },
          ]
        : notifications?.map((notification) => ({
            key: notification.conversation_code,
            label: (
              <div
                className="notification-item"
                onClick={(e) => {
                  setNotifications &&
                    setNotifications((prev) =>
                      prev.filter(
                        (item) =>
                          item.conversation_code !==
                          notification.conversation_code
                      )
                    );
                  navigation(Urls.messages, {
                    state: {
                      id: notification.id,
                      conversation_code: notification.conversation_code,
                    },
                  });
                    console.log("notification menu click ::=>",notification)
                    FetchSaveNotificationReaded([notification.conversation_code]).then((response) => {
                      console.log("response save notification readed menu ==>",response);
                    }).catch((error) => {
                      console.log("error save notification readed menu ==>",error);
                    })
                }}
              >
                <div className="name-user"> {notification?.username}</div>
                <div className="email-user">
                  {notification?.conversation_email}
                </div>
                <div className="message-user">{notification?.message}</div>
              </div>
            ),
          }));
    return <Menu items={menuItems} />;
  };
  const extraPageHeader = [
    <Dropdown overlay={menu} key={"profile"} placement="bottomRight">
      <button
        className="container-menu-dropdown-toggle"
        onClick={(e) => {
          e.preventDefault();
        }}
        key={"profile"}
      >
        <ProfileIcon />
      </button>
    </Dropdown>,
    <Dropdown
      overlay={mapNotifications}
      key={"notifications"}
      placement="bottomRight"
    >
      <button
        className="container-menu-dropdown-toggle"
        onClick={(e) => {
          e.preventDefault();
        }}
        key={"notification"}
      >
        <Badge count={notifications.length} showZero={false}>
          <NotificationIcon />
        </Badge>
      </button>
    </Dropdown>,
  ];

  return (
    <AppLayout>
      <div className="container-dashboard">
        <PageHeader
          className="site-page-header"
          title={<HeadTitle />}
          extra={extraPageHeader}
        />
        <div className="content-dashboard">{children}</div>
      </div>
    </AppLayout>
  );
};

export default DashboardContainer;
