import React from 'react'

export default function NotificationIcon() {
  return (
    <svg width="31" height="34" viewBox="0 0 31 34" fill="none" xmlns="http://www.w3.org/2000/svg" className='aion-notification-icon'>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.7999 0.409912L16.22 0.429932L16.6399 0.539917L17.0099 0.679932L17.3499 0.899902L17.66 1.1499L17.93 1.44995L18.1399 1.77991L18.31 2.15991L18.41 2.54993L18.4599 2.96997L19.2599 3.17993L20.0299 3.43994L20.7799 3.76001L21.49 4.13L22.18 4.54993L22.84 5.01001L23.4599 5.51001L24.0499 6.04993L24.59 6.64001L25.0999 7.23999L25.56 7.89001L25.97 8.57996L26.34 9.29993L26.65 10.0399L26.91 10.7999L27.13 11.59L27.2799 12.3999L27.3699 13.23L27.4 14.08V21.76L27.88 21.7799L28.33 21.88L28.7599 22.0299L29.1699 22.22L29.54 22.48L29.88 22.7699L30.16 23.1L30.4199 23.46L30.63 23.86L30.7799 24.2799L30.86 24.73L30.8899 25.2V27.62H0.699951V25.2L0.719952 24.73L0.829952 24.2799L0.97995 23.86L1.16995 23.46L1.43995 23.1L1.72995 22.7699L2.05995 22.48L2.42995 22.22L2.82995 22.0299L3.25995 21.88L3.71995 21.7799L4.18995 21.76V14.08L4.22995 13.23L4.31995 12.3999L4.46995 11.59L4.67995 10.7999L4.93995 10.0399L5.25995 9.29993L5.61995 8.57996L6.03995 7.89001L6.49995 7.23999L7.00995 6.64001L7.54995 6.04993L8.13995 5.51001L8.74995 5.01001L9.41995 4.54993L10.0999 4.13L10.82 3.76001L11.57 3.43994L12.34 3.17993L13.1399 2.96997L13.1899 2.54993L13.29 2.15991L13.45 1.77991L13.6699 1.44995L13.9399 1.1499L14.25 0.899902L14.5999 0.679932L14.9799 0.539917L15.38 0.429932L15.7999 0.409912Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.76 29.6401H20.83L20.65 30.2001L20.4 30.7501L20.12 31.2501L19.79 31.7201L19.4 32.1401L18.97 32.53L18.51 32.8601L18.03 33.15L17.5 33.3801L16.97 33.52L16.39 33.6401L15.8 33.67L15.2 33.6401L14.65 33.52L14.1 33.3801L13.57 33.15L13.08 32.8601L12.63 32.53L12.19 32.1401L11.81 31.7201L11.48 31.2501L11.18 30.7501L10.95 30.2001L10.76 29.6401Z" fill="white"/>
    </svg>

  )
}
