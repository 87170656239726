import React, {  useContext } from "react";
import {Link,  useLocation, useNavigate} from "react-router-dom";
import {Layout, Menu,Space} from 'antd';
import { CollapsedMenuContext } from "components/contexts/CollapsedMenuContext";
import {HomeIcon,AnalyticsIcon,MessagesChatIcon,LogoAionIcon,SettingIcon,CodeIcon, ConfigChatIcon} from "components/Icons"
import {DoubleLeftOutlined,DoubleRightOutlined,} from "@ant-design/icons";
import "./sidebar.less"
import { Urls } from "Constants";
const { Sider } = Layout;

const Sidebar: React.FC = () => {

    const location = useLocation();
    const navigation = useNavigate();
    const { isMenuCollapsed,setIsMenuCollapsed } = useContext(CollapsedMenuContext);


    const items = [
        {
            label: "Dashboard",
            key: Urls.dashboard,
            icon: ( <AnalyticsIcon />)
        },
        {
            label: "Intents",
            key: Urls.intent,
            icon: ( <SettingIcon />)
        },


        {
            label: "Messages",
            key: Urls.messages,
            icon: ( <MessagesChatIcon />
            )
        },
        {
            label: "Planning",
            key: Urls.planning,
            icon: ( <HomeIcon />
            )
        },
        {
            label: "ChatBot settings",
            key: Urls.chatBotSettings,
            icon: ( <CodeIcon />)
        },
        
    ];

    return (
        <Sider  
        collapsible collapsed={isMenuCollapsed} onCollapse={(value) => setIsMenuCollapsed && setIsMenuCollapsed(value)}
        className="custom-said-bar-menu"  >
            <Space size="small" style={{padding: ' 16px 3px 50px 3px'}} direction={isMenuCollapsed?"vertical":"horizontal"}>
                <Link to="/">
                    <LogoAionIcon />
                </Link>
                <button className="btn-none-style"  onClick={() =>{
                    //dispatch(isCollapsedMenu({error:null,collapsed:!collapsed}))
                    setIsMenuCollapsed && setIsMenuCollapsed(!isMenuCollapsed)}}
                            >
                {!isMenuCollapsed ? <DoubleLeftOutlined />:<DoubleRightOutlined />
}                </button>
            </Space>

            <Menu items={items} selectedKeys={[location.pathname]}  onClick={({key})=>{navigation(key)}} />
        </Sider>
    );
}

export default Sidebar;
