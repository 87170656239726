import React from "react"
import {ModalProps} from "antd/lib/modal"
import {Modal} from "antd"
import "./modal.less"

const AppModal:React.FC<ModalProps> = ({children,className="",...props})=>{
    return (
    <Modal
        className={`custom-modal ${className}`}
        {...props}
        >
            {children}
    </Modal>)
}

export default AppModal