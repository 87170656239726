import { AxiosError, AxiosResponse } from "axios";
import React, { createContext, useReducer, useEffect } from "react";
import {loginHandler,fetchCurrentUser} from "services/user";
import { AuthState, AuthAction, UserInterface } from "interface/services";
import { useNavigate } from "react-router-dom";

// Create the initial state
const initialState: AuthState = {
  token: localStorage.getItem("access_token"),
  refreshToken: localStorage.getItem("refresh_token"),
  user: null,
};

// Create the reducer function
const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload?.user ?? null,
      };

    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload?.token ?? null,
        refreshToken: action.payload?.refreshToken ?? null,
      };
    case "LOGOUT":
      return {
        ...state,
        token: null,
        refreshToken: null,
        user: null,
      };
    default:
      return state;
  }
};

// Create the AuthContext
export const AuthContext = createContext<{
  state: AuthState;
  dispatch: React.Dispatch<AuthAction>;
  handleLogin: (email: string, password: string) => Promise<unknown>;
  handleLogout: () => void;
}>({
  state: initialState,
  dispatch: () => null,
  handleLogin: async () => {},
  handleLogout: () => {},
});

// Create the AuthProvider component
export const AuthProvider: React.FC<React.PropsWithChildren> = ({children}) => {

  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate()
  
  useEffect(() => {
    if(state.token){
      fetchCurrentUser()
      .then((response: AxiosResponse) => {
        dispatch({type:"SET_USER",payload:{...state, user:response.data as UserInterface}})
      })
      .catch((error: AxiosError) => {
        return console.log("Error :",error);
      });
    }else{
      handleLogout()
    }
  }, [state.token]);

  const handleLogin = async (
    email: string,
    password: string
  ): Promise<string> => {
    try {
      // Call your login API here, passing email and password
      const response = await loginHandler(email, password);
      const { access, refresh } = response.data;

      localStorage.setItem("access_token", access);
      localStorage.setItem("refresh_token", refresh);
      dispatch({
        type: "SET_TOKEN",
        payload: { user: null, refreshToken: refresh, token: access  },
      });
      return "login success";
    } catch (error) {
      // Handle login error
      throw new Error("login failed");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    dispatch({ type: "LOGOUT" });
  };

  return (
    <AuthContext.Provider
      value={{ state, dispatch, handleLogin, handleLogout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
