
import {RequireNotAuth,RequireCompleteInscription,RequireNotCompleteInscription} from "Middleware";
import { Route, Routes } from "react-router-dom";
import Home from "containers/Home";
import Login from "containers/Login";
import Dashboard from "containers/Dashboard";
import Messages from "containers/Messages";
import Intent from "containers/Intent";
import Planning from "containers/Planning";
import SignUp from "containers/SignUp";
import Setup from "containers/Setup";
import { Urls } from "Constants";
import ChatBotSettings  from 'containers/ChatBotSettings'

const AppRoute = () =>{
    return(
        <Routes>
        <Route 
            path={Urls.planning} 
            element={<RequireCompleteInscription><Planning /></RequireCompleteInscription>} />
        <Route 
            path={Urls.messages} 
            element={<RequireCompleteInscription><Messages /></RequireCompleteInscription>} />
        <Route 
            path={Urls.intent} 
            element={<RequireCompleteInscription><Intent /></RequireCompleteInscription>} />
        <Route 
            path={Urls.dashboard} 
            element={<RequireCompleteInscription><Dashboard /></RequireCompleteInscription>}/>
        <Route 
            path={Urls.home} 
            element={<RequireCompleteInscription><Home /></RequireCompleteInscription>} />
        <Route
            path={Urls.chatBotSettings} 
            element={<RequireCompleteInscription><ChatBotSettings /></RequireCompleteInscription>} />
        
        <Route path={Urls.setup} element={<RequireNotCompleteInscription><Setup /></RequireNotCompleteInscription>}/>

        <Route path={Urls.login} element={<RequireNotAuth><Login /></RequireNotAuth>} />
        <Route path={Urls.signUp} element={<RequireNotAuth><SignUp /></RequireNotAuth>}/>
      </Routes>

    )
}


export default AppRoute