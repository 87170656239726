import { Layout, Button } from 'antd';
import React, {PropsWithChildren} from 'react';
import Sidebar from "../Sidebar";

import LeftSquareOutlined  from '@ant-design/icons';

const { Content } = Layout;

type Props = PropsWithChildren;

const AppLayout: React.FC<Props> = props => {
    return (
        <Layout style={{minHeight: "100vh"}}>
            <Sidebar />
            <Layout>
                <Content style={{backgroundColor: '#FFF'}}>{props.children}</Content>
            </Layout>
        </Layout>
    );
}

export default AppLayout;