import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import FullWidthLayout from "../FullWidthLayout";
import { Alert, Form } from "antd";
import { InputText, InputButton, FlashMessage } from "components/Global";
import { MessageIcon, LockIcon } from "components/Icons";
import "./login.less";
import { AuthContext } from "components/contexts/UserContext";
import { Urls } from "Constants";
import { AxiosError } from "axios";

const Login: React.FC = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const navigate = useNavigate();
  const { handleLogin } = useContext(AuthContext);
  const handelSubmitLogin = () => {
    setError("");
    if(!password || !email){      
      setError("Please fill all fields")
      return
    }
    handleLogin(email, password)
      .then((e) => {
        navigate(Urls.dashboard, {
          replace: true,
          state: { displayModal: true },
        });
      })
      .catch((err: AxiosError) => {
        setError("Invalid cardinalities");
      });
  };
  return (
    <FullWidthLayout>
      <div className="container-login">
        <div
          className="chat-login"
          style={{
            paddingTop: "50px",
          }}
        >
          <FlashMessage
            duration={5000}
            sessionsName="register"
            type="warning"
            className="container-alert-register "
          />
          <h1 className="container-header-page login">welcome back</h1>
          {error && (
            <Alert message={error} type="error" className="min-width-md" />
          )}
          <Form
            className="login-form"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={() => handelSubmitLogin()}
            autoComplete="off"
          >
            <InputText
              name="email"
              placeholder="Email"
              prefix={<MessageIcon />}
              theme="primary"
              className="custom-input-prefix"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <InputText
              name="password"
              placeholder="password"
              prefix={<LockIcon />}
              theme="primary"
              className="custom-input-prefix"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
            <InputButton
              type="primary"
              htmlType="submit"
              color="red"
              block
              className="custom-btn-login"
            >
              Login
            </InputButton>
            <Link to={Urls.signUp} className="custom-link-style login">
              Sign up
            </Link>
          </Form>
        </div>
        <div className="container-background"></div>
      </div>
    </FullWidthLayout>
  );
};

export default Login;
