import React from "react";
import "./InputButton.less";
import { TypeInputButton } from "../../../interface/components"
import { Button } from "antd";

const InputButton: React.FC<React.PropsWithChildren<TypeInputButton>> = ({color,className="",...props}) => {
  const classButton = color ?`custom-button-${color} ${className}`:className;
  return <Button className={classButton} {...props}>{props.children}</Button>;
};

export default InputButton;
