import React, { useState } from "react";
import "./SignUp.less";
import { InputButton, InputText, InputDatePicker } from "components/Global";
import { fetchRegistration } from "services/user";
import { useNavigate } from "react-router-dom";
import {
  LockIcon,
  MessageIcon,
  ProfileSecondaryIcon,
  PhoneIcon,
  LocationIcon,
} from "components/Icons";
import moment from "moment";
import { Alert, Checkbox } from "antd";
import { Urls, MessageSuccessRegister,MessageErrorRequire, StringError } from "Constants";
import { Formik} from "formik";
import * as yup from "yup";
import * as  validation from "Constants/validation"
const AppRegistration = () => {
  const navigation = useNavigate();
  const [IsAgree, SetIsAgree] = useState<boolean>(false);
  const [error, setError] = React.useState("");
  

  const validationForm = yup.object().shape({
    email: validation.validationEmail.required(MessageErrorRequire),
    first_name: validation.validationName("first name").required(MessageErrorRequire),
    last_name: validation.validationName("last name").required(MessageErrorRequire),
    phone: validation.validationPhone.required(MessageErrorRequire),
    username: validation.validationName("username").required(MessageErrorRequire),
    password: validation.validationStringRequire.min(8,StringError("password")),
    password2: validation.validationStringRequire.min(8,StringError("confirm password")),
  })
  const initialValue = {
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    username: "",
    password: "",
    password2: "",
  };

  const HandelSubmitRegister = (e:any) => {
    fetchRegistration(e)
      .then(() => {
        localStorage.setItem("register", MessageSuccessRegister);
        navigation(Urls.login);
      })
    .catch(() => {
        setError("please enter valid information");
    });
  };
  return (
    <div className="full-container container-sign-up">
      <Formik
        initialValues={initialValue}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationForm}
        onSubmit={HandelSubmitRegister}
      >
        {(formik) => (
          <form
            className="ant-form ant-form-vertical form-sign-up"
            onSubmit={e => IsAgree && formik.handleSubmit(e)}
          >
            <div className="sign-up-inputs">
              <h1 className="content-sing-up">Sing Up</h1>
              {error && (
                <Alert
                  message={error}
                  type="error"
                  className="min-width-md"
                  style={{ marginTop: "20px" }}
                />
              )}
              <InputText
                prefix={<ProfileSecondaryIcon />}
                className="custom-input-prefix bg-primary"
                placeholder="First name"
                value={formik.values.first_name}
                theme="secondary"
                name="first_name"
                onChange={formik.handleChange}  
                onError={formik.errors?.first_name}
              />

              <InputText
                prefix={<ProfileSecondaryIcon />}
                className="custom-input-prefix bg-primary"
                placeholder="Last Name"
                value={formik.values.last_name}
                theme="secondary"
                name="last_name"
                onChange={formik.handleChange}  
                onError={formik.errors?.last_name}
              />

              <InputText
                prefix={<ProfileSecondaryIcon />}
                className="custom-input-prefix bg-primary"
                placeholder="Username"
                value={formik.values.username}
                theme="secondary"
                name="username"
                onChange={formik.handleChange}  
                onError={formik.errors?.username}
              />

              <InputText
                prefix={<MessageIcon />}
                className="custom-input-prefix bg-primary"
                placeholder="Email"
                value={formik.values.email}
                theme="secondary"
                type="email"
                name="email"
                onChange={formik.handleChange}  
                onError={formik.errors?.email}
              />

              <InputText
                prefix={<PhoneIcon />}
                className="custom-input-prefix bg-primary"
                placeholder="Phone"
                value={formik.values.phone}
                theme="secondary"
                name="phone"
                onChange={formik.handleChange}  
                onError={formik.errors?.phone}
              />

              <InputText
                prefix={<LockIcon />}
                className="custom-input-prefix bg-primary"
                placeholder="Password"
                value={formik.values.password}
                theme="secondary"
                type="password"
                name="password"
                onChange={formik.handleChange}  
                onError={formik.errors?.password}
              />

              <InputText
                prefix={<LockIcon />}
                className="custom-input-prefix bg-primary"
                placeholder="Confirm password"
                value={formik.values.password2}
                theme="secondary"
                type="password"
                name="password2"
                onChange={formik.handleChange}  
                onError={formik.errors?.password2}
              />
              <div className="container-check-agree">
                <Checkbox
                  checked={IsAgree}
                  onChange={(e) => SetIsAgree(e.target.checked)}
                >
                  I Agree to Privacy Policy
                </Checkbox>
              </div>
              <InputButton
                disabled={!IsAgree}
                type="primary"
                className="btn-lg btn-sign-up"
                htmlType="submit"
              >
                Sing in
              </InputButton>
            </div>
          </form>
        )}
      </Formik>

      <div className="container-bg"></div>
    </div>
  );
};
export default AppRegistration;
