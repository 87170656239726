import React, { useContext, useEffect, useMemo, useState } from "react";
import "./setup.less";
import { ChatBotConfiguration, BillingInformation } from "./Steps";
import { CustomSteps, InputButton } from "components/Global";
import { fetchSaveSteps } from "services/user";
import { AxiosError, AxiosResponse } from "axios";
import { AuthContext } from "components/contexts/UserContext";
import { LogoutOutlined } from "@ant-design/icons";
const AppRegistration = () => {

  const {state,dispatch} = useContext(AuthContext)
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoad, setIsLoad] = useState(false);
  
  //Constants
  const titlesSteps = ["Intent Configuration", "Payment Information"];
  const RenderContent = () => {
    const memoizedContent = useMemo(() => {
      if (currentStep !== 2)
        return <ChatBotConfiguration setCurrentStep={setCurrentStep} />;
      return <BillingInformation />;
    }, [currentStep]);

    return memoizedContent;
  };

  useEffect(() => {
    isLoad && fetchSaveSteps(currentStep).then((e:AxiosResponse)=>{}).catch((e:AxiosError)=>{console.log("Error Set Step ",e.message)})
    setIsLoad(true)
  }, [currentStep]);

  useEffect(()=>{
    setCurrentStep(state.user?.inscription_step ?? 1)
  },[])

  return (
    <div className="full-container container-registration">
      <div className="container-Steps">
        <InputButton className="btn-lg btn-logout" onClick={(e)=>{
          dispatch({type:"LOGOUT"})
        }} >
        <LogoutOutlined /> Logout
        </InputButton>
        <div className="container-content">{RenderContent()}</div>
        <div className="container-actions">
          {currentStep === 2 && (
            <InputButton
              type="ghost"
              className="btn-prev"
              onClick={() => setCurrentStep(1)} >
              previous
            </InputButton>
          )}
        </div>
        <div className="container-custom-step">
          <CustomSteps
            steps={titlesSteps.map((title) => ({ title: "" }))}
            current={currentStep-1}
            progressDot
          />
        </div>
      </div>
      <div className="container-setup-bg"></div>
    </div>
  );
};
export default AppRegistration;
