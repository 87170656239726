import React, { useEffect, useState } from "react";
import { Drawer,message as messageCmp,Checkbox, Switch} from "antd";
import "./intent.less";
import {
  getIntentsDetails,
  updateEntenteMessage,
} from "../../services/message";
import { AxiosError, AxiosResponse } from "axios";
import "./intent.less";
import { InputButton, CustomTextArea } from "components/Global";
import { IntentSaveSuccess, MessageErrorRequire } from "Constants";

type intentDetails = {
  desc: string;
  intent: number;
  message?: string;
  name: string;
  user: string;
  enabled: boolean;
};
interface PropsType {
  setIdIntent: React.Dispatch<React.SetStateAction<number | null>>;
  idIntent: number | null;
}
const DrawerIntent: React.FC<PropsType> = ({ idIntent, setIdIntent }) => {
    const [dataIntent, setDataIntent] = useState<intentDetails>();
    const [error, setError] = useState("");

  useEffect(() => {
    if (idIntent) {
      getIntentsDetails(idIntent)
        .then((response: AxiosResponse) => {
          setDataIntent(response.data);
        })
        .catch(() => {
          console.log("error get intents");
        });
    } else {
      setDataIntent({ desc: "", intent: 0, name: "", user: "", message: "" ,enabled:false});
    }
  }, [idIntent]);

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError("")
    if(!dataIntent?.message){
        setError(MessageErrorRequire)
        return
    }
    if (dataIntent) {
      const { intent, message,enabled } = dataIntent;
      updateEntenteMessage({ intent, message ,enabled})
        .then((response: AxiosResponse) => {
          setIdIntent(null)
          messageCmp.success({content:IntentSaveSuccess})
        })
        .catch((err: AxiosError) => {
          console.log(err);
        });
    }
  };

  return (
   <>
     <Drawer
      className="drawer-container"
      placement="right"
      open={!!idIntent}
      onClose={() => setIdIntent(null)}
    >
      <div className="container-details-intent">
        <h3  className="page-title">
          Configuration Message
        </h3>
        <div className="intent-infos">
          <div className="block-top">
            <h5 className="header-lbl">Name: </h5>
            <p className="description-text paragraph">{dataIntent?.name}</p>
            <h5 className="header-lbl">Description: </h5>
            <p className="description-text paragraph">{dataIntent?.desc}</p>
          </div>

          <form onSubmit={onSubmitHandler} className="message-form">
            <CustomTextArea
              theme="primary"
              label="Message"
              rows={5}
              name="message"
              className="message-area"
              onChange={(e) =>
                setDataIntent((prev: any) => ({...prev,message: e.target.value}))
              }
              value={dataIntent?.message}
              onErrorLabel={error}
            >
              Message
            </CustomTextArea>
            <Switch
                className="intent-checkbox"
                checkedChildren={"Enabled"}
                unCheckedChildren={"Disabled"}
                onChange={(e)=>{ setDataIntent((prev: any) => ({...prev,enabled: e}))}}
                checked={dataIntent?.enabled}
            />
            <div className="container-btn-save-intent">
              <InputButton type="primary" htmlType="submit" block>
                Save
              </InputButton>
            </div>
          </form>
        </div>
      </div>
    </Drawer>
   </>
  );
};

export default DrawerIntent;
