import "./App.less";
import { AuthProvider } from "components/contexts/UserContext";
import NotificationProvider from "components/contexts/NotificationContextProvider";
import CollapsedProvider from "components/contexts/CollapsedMenuContext";
import Routes from "./router";
import GuidingUser from "containers/GuidingUser";

function App() {
  return (
    <AuthProvider>
      <NotificationProvider>
        <CollapsedProvider>
          <GuidingUser>
            <Routes />
          </GuidingUser>
        </CollapsedProvider>
      </NotificationProvider>
    </AuthProvider>
  );
}

export default App;
