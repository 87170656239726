export const Menu = {
  home: {
    title: "Welcome to Dashboard Aion Chat",
    text: "The dashboard page displays statistics on customer interactions with the chatbot. It provides valuable insights and data related to customer engagement. The page includes sections for an overview, interaction metrics, user demographics, conversation analysis, conversion tracking, and visualizations. The overview summarizes key metrics, while interaction metrics show the number of conversations initiated, completed, and abandoned. User demographics offer insights into customer profiles, and conversation analysis provides details on conversation duration and frequently asked questions. Conversion tracking measures successful conversions, and visualizations aid in data interpretation.",
  },
  planning: {
    title: "Planning page",
    text: "The planning page provides users with a view of the appointments scheduled by the chatbot. It offers a comprehensive overview of upcoming appointments and their associated details. When you click on a specific appointment, it redirects you to the conversation that led to the scheduling of that appointment. This allows you to review the conversation history and gather relevant context before the appointment takes place. The planning page serves as a centralized hub for managing and organizing scheduled appointments, ensuring that users have easy access to their upcoming engagements. By providing a direct link to the conversation, it enables users to retrieve information and prepare adequately for their appointments.",
  },
  message: {
    title: "Page Message",
    text: "The messages page provides a live view of the ongoing conversations between the chatbot and customers. It allows you to monitor and engage directly with customers in real-time. On this page, you can observe the chatbot's responses as well as the messages exchanged by customers. It provides a convenient interface to view the conversation history, enabling you to understand the context and previous interactions. Moreover, the messages page allows you to actively participate in the conversation by sending messages directly to customers. This interactive feature enables you to provide real-time assistance, address specific queries, or gather additional information as needed. Overall, the messages page serves as a communication hub, facilitating seamless interactions between the chatbot and customers while giving you the ability to intervene and engage when necessary.",
  },
  intents: {
    title: "Intents configuration page",
    text: "The intents configuration page allows you to set up and manage the intents for your chatbot. Here, you can define the different intents that your chatbot will recognize and respond to. By clicking on an intent, you can compose the corresponding response and customize it according to your needs. Additionally, you have the option to enable or disable specific intents, giving you control over which intents are active in your chatbot. This page serves as a central hub for managing the intent functionality of your chatbot, allowing you to fine-tune its responses and tailor its behavior to provide the desired user experience.",
  },
  integration: {
    title: "Integration page",
    text: "The integration pages provide you with the necessary code to seamlessly integrate the chatbot into your website or quickly integrate it using the WordPress extension. These pages offer a convenient solution for embedding the chatbot functionality into your online platform. If you have a website, you will find a code snippet that you can easily copy and paste into the HTML code of your web pages. This code ensures that the chatbot appears and functions correctly on your site. Alternatively, if you use WordPress as your website platform, you can find a dedicated extension that simplifies the integration process.",
  },
  chatBotSettings: {
    title: "Settings page",
    text: "The settings page allows you to configure the essential parameters necessary for the chatbot to be fully functional. This page provides a simple and user-friendly interface where you can adjust various settings to customize the chatbot according to your requirements. The settings typically include: Host Configuration: Here, you can configure the host settings to specify where the chatbot will be deployed. This involves providing the necessary details such as the domain or subdomain where the chatbot will be accessible. Service Configuration: This section allows you to define the specific service or purpose of the chatbot. You can provide a description of the service it offers, which helps set the context for users interacting with the chatbot. Color Customization: The settings page also provides options to customize the appearance of the chatbot. You can choose the color scheme or theme that aligns with your branding or website design, creating a visually cohesive experience for users. Service Description: This element allows you to provide a detailed description of the service or functionalities offered by the chatbot. It helps users understand the capabilities and benefits of interacting with the chatbot, enhancing their overall experience. By accessing the settings page, you can easily configure these parameters, ensuring that the chatbot is properly tailored to your specific needs. These settings play a crucial role in making the chatbot functional and aligned with your branding and service objectives.",
  },
  profile: {
    title: "Profile",
    text: "",
  },
  notification: {
    title: "Notifications",
    text: "The notifications feature keeps you informed about conversations and prompts you to intervene when necessary. With notifications enabled, you receive real-time alerts whenever there is a new conversation. This ensures that you stay updated and can respond promptly to customer inquiries or requests.Additionally, the notifications system identifies conversations that require your direct intervention. When such conversations occur, you receive specific notifications highlighting the need for your immediate attention. This allows you to step in and engage directly with the user, providing personalized assistance or resolving complex queries. The notifications feature serves as a valuable tool to stay connected and responsive to customer interactions. By receiving timely notifications, you can effectively manage conversations, deliver exceptional customer support, and maintain a high level of engagement with your users.",
  },
  canvas: {
    title: "Analytics",
    text: "",
  },
};

export const PropsShepherdTour = (type?: "first" | "last") => {
  const buttons = [
    {
      classes: "button-secondary",
      text: "Exit",
      type: "cancel",
    },
    {
      classes: "button-primary",
      text: "Back",
      type: "back",
    },
    {
      classes: "button-primary",
      text: "Next",
      type: "next",
    },
  ];

  if (type === "first") {
    buttons.splice(1, 1);
  } else if (type === "last") {
    buttons[2].text = "Finish";
  }

  return {
    classes: "aion-guiding-user-container",
    highlightClass: "highlight",
    scrollTo: false,
    buttons: buttons,
  };
};