import React, { useContext, useEffect, useState } from "react";
import {CustomTextArea,InputText,InputButton,ColorPicker} from "components/Global";
import { ChatUser } from "interface/services";
import { AxiosError, AxiosResponse } from "axios";
import { fetchSaveInfo, fetchCreateInfo, fetchTagsInfo } from "services/user";
import { AuthContext } from "components/contexts/UserContext";
import * as validation from "Constants/validation";
import * as yup from "yup";
import { Formik } from "formik";
import {Select} from "antd";

interface Props {
  setCurrentStep?: React.Dispatch<React.SetStateAction<number>>;
}
interface servicesPropsType {
    id: number;
    name: string;
    color: string;
    description: string;
}
const ChatBotConfiguration: React.FC<Props> = ({ setCurrentStep }) => {
  const { state, dispatch } = useContext(AuthContext);
  const [initialValue, setInitialValue] = useState<ChatUser>({
    desc: "",
    host: "",
    name: "",
    logo: null,
      // services: [] , not working
  });
  const [ServicesType, setServicesType] = useState<any>(null);

  const validationForm = yup.object().shape({
    desc: validation.validationStringRequire,
    host: validation.validationHostRequire,
    name: validation.validationStringRequire,
  });

  useEffect(() => {    
    state.user?.chat_user && setInitialValue(state.user?.chat_user);
  }, []);

    useEffect(() => {
        fetchTagsInfo().then((response: AxiosResponse) => {
            console.log("list data",response.data)
            setServicesType(response.data);
        })
    }, []);

  const HandelSubmitRegister = (e: ChatUser) => {
    if(state?.user?.chat_user){
        console.log("id state fetch",e.id)
        fetchSaveInfo({ ...e, service: e.desc,host:`https://${e.host}`,logo:e.logo !== "/images/default.png"?e.logo:null})
        .then((response: AxiosResponse) => {
            setCurrentStep && setCurrentStep(2)

            dispatch({
            type: "SET_USER",
            payload: {
                user: { ...state.user, chat_user: {...e,host:`https://${e.host}`} },
                refreshToken: null,
                token: null,
            },
            });
        })
        .catch((error: AxiosError) => {
            console.log("error", error);
        });
    }else {
      fetchCreateInfo({ ...e, service: e.desc,host:`https://${e.host}`, user: state?.user?.id})
          .then((response: AxiosResponse) => {
            setCurrentStep && setCurrentStep(2)

            dispatch({
              type: "SET_USER",
              payload: {
                user: { ...state.user, chat_user: {...e,host:`https://${e.host}`} },
                refreshToken: null,
                token: null,
              },
            });
          })
          .catch((error: AxiosError) => {
            console.log("error", error);
          });
    }

  };

  console.log(initialValue)

  return (
    <Formik
      initialValues={initialValue}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationForm}
      onSubmit={HandelSubmitRegister}
    >
      {(formik) => (
        <form
          className="container-intent-configuration"
          onSubmit={formik.handleSubmit}
        >
          <div className="ant-form ant-form-vertical content-intent-configuration">
            <div className="container-inputs">
              <h1 className="title-additional-information">
              Configuration Chatbot
              </h1>
              <InputText
                label="Website name : *"
                placeholder="example"
                theme="secondary"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onError={formik.errors?.name}
                classNameLabel="custom-label-style"
              />
              <InputText
                label="Domain Name  : *"
                addonBefore="https://"
                allowClear
                placeholder="example.com"
                theme="secondary"
                name="host"
                value={formik.values.host}
                onChange={formik.handleChange}
                onError={formik.errors?.host}
                classNameLabel="custom-label-style domain-name"
              />
              <CustomTextArea
                theme="secondary"
                label="Service  : *"
                placeholder="Enter description about Services your website"
                classNameLabel="custom-label-style"
                rows={7}
                name="desc"
                value={formik.values.desc}
                onChange={formik.handleChange}
                onErrorLabel={formik.errors?.desc}
              />
                {/*<Select*/}
                {/*    mode={"tags"}*/}
                {/*    className="select-tags"*/}
                {/*    placeholder="Please select"*/}
                {/*    onChange={(value:any)=>formik.setFieldValue("services",value)}*/}
                {/*    value={formik.values.services}*/}
                {/*    options={ServicesType?.map((service: servicesPropsType) => ({ label: service.name, value: service.id }))}*/}
                {/*/>*/}
            </div>
          </div>

          <InputButton
            type="primary"
            className="btn-lg btn-next"
            htmlType="submit"
          >
            Next
          </InputButton>
        </form>
      )}
    </Formik>
  );
};
export default ChatBotConfiguration;
