import React from "react";
interface PropsType {
  color?: string;
  name?: string;
  chatLogo?: string;
  userMessageBgColor?: string;
  userMessagerColor?: string;
  adminMessagerColor?: string;
  adminMessageBgColor?: string;
  titleColor?: string;
  bodyChatBackgroundColor?: string;
}
const defaultLogo = "https://i.ibb.co/0jZyQYH/logo.png";
const URL = process.env.REACT_APP_API_BASE_URL;

const AppPreviewChat: React.FC<PropsType> = (
    {
      color = "#0066CCFF",
      userMessageBgColor,
      name="My assistant",
      chatLogo=defaultLogo,
      userMessagerColor,
      adminMessagerColor,
      adminMessageBgColor,
      titleColor="#000",
      bodyChatBackgroundColor
    }) => {
  const shadowStyle = { filter: `drop-shadow(5px 17px 32px ${color})` };
  return (
   <>
    <h3 className="header-page header-preview-chatbot">Preview chat-bot</h3>
    <div className="container-preview">
    <div className="content-preview">
      <div className="card-chat-box animation">
        <div className="card">
          <div className="card-header" style={{backgroundColor: color}}>
            <h2 style={{color: titleColor}}>{name}</h2>
            <div>
              <img
                src={URL +""+ chatLogo}
                alt=""
                height="60"
                width="60"
              />
            </div>
            <p style={{color: titleColor}}>Aion is a chatbot platform that allows you to create your own chatbot in minutes.</p>
          </div>
          <div className="card-body" style={{backgroundColor: bodyChatBackgroundColor}}>
            <div className="display-none"></div>
            <div
              className="chat-message-box sent"
              style={{
                backgroundColor: userMessageBgColor,
                color: userMessagerColor
            }}
            >
              sent message
            </div>
            <div className="chat-message-box received"
                 style={{
                   backgroundColor: adminMessageBgColor,
                   color: adminMessagerColor
                 }}
            >received message</div>
            <div className="chat-message-box received message-box-typing">
              <div className="typing typing-1"></div>
              <div className="typing typing-2"></div>
              <div className="typing typing-3"></div>
            </div>
          </div>

        </div>
        <div className="card-footer">
          <div className="message-container">
            <input
                type="text"
                className="input-message"
                placeholder="Message"
                id="input-message"
                name="input-message"
            />
          </div>

          <button
              className="btn btn-sent"
              id="btn-sent-message"
              type="submit"
              style={{backgroundColor: color}}
          >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                width="25"
                height="25"
                viewBox="0 0 256 256"
                xmlSpace="preserve"
            >
              <defs></defs>
              <g
                  className="g-element"
                  transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
              >
                <path
                    d="M 89.999 3.075 C 90 3.02 90 2.967 89.999 2.912 c -0.004 -0.134 -0.017 -0.266 -0.038 -0.398 c -0.007 -0.041 -0.009 -0.081 -0.018 -0.122 c -0.034 -0.165 -0.082 -0.327 -0.144 -0.484 c -0.018 -0.046 -0.041 -0.089 -0.061 -0.134 c -0.053 -0.119 -0.113 -0.234 -0.182 -0.346 C 89.528 1.382 89.5 1.336 89.469 1.29 c -0.102 -0.147 -0.212 -0.288 -0.341 -0.417 c -0.13 -0.13 -0.273 -0.241 -0.421 -0.344 c -0.042 -0.029 -0.085 -0.056 -0.129 -0.082 c -0.118 -0.073 -0.239 -0.136 -0.364 -0.191 c -0.039 -0.017 -0.076 -0.037 -0.116 -0.053 c -0.161 -0.063 -0.327 -0.113 -0.497 -0.147 c -0.031 -0.006 -0.063 -0.008 -0.094 -0.014 c -0.142 -0.024 -0.285 -0.038 -0.429 -0.041 C 87.03 0 86.983 0 86.936 0.001 c -0.141 0.003 -0.282 0.017 -0.423 0.041 c -0.035 0.006 -0.069 0.008 -0.104 0.015 c -0.154 0.031 -0.306 0.073 -0.456 0.129 L 1.946 31.709 c -1.124 0.422 -1.888 1.473 -1.943 2.673 c -0.054 1.199 0.612 2.316 1.693 2.838 l 34.455 16.628 l 16.627 34.455 C 53.281 89.344 54.334 90 55.481 90 c 0.046 0 0.091 -0.001 0.137 -0.003 c 1.199 -0.055 2.251 -0.819 2.673 -1.943 L 89.815 4.048 c 0.056 -0.149 0.097 -0.3 0.128 -0.453 c 0.008 -0.041 0.011 -0.081 0.017 -0.122 C 89.982 3.341 89.995 3.208 89.999 3.075 z M 75.086 10.672 L 37.785 47.973 L 10.619 34.864 L 75.086 10.672 z M 55.136 79.381 L 42.027 52.216 l 37.302 -37.302 L 55.136 79.381 z"
                    className="g-path-class"
                    transform=" matrix(1 0 0 1 0 0) "
                    stroke-linecap="round"
                    fill={titleColor}
                />
              </g>
            </svg>
          </button>
        </div>
      </div>
        <button className="btn-close">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 30 30"
              width="25px"
              height="25px"
          >
            <path
                fill={color}
                d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"
            ></path>
          </svg>
        </button>
    </div>
    </div>
   
   </>
    
  );
};

export default AppPreviewChat;
