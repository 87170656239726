import React from "react";
import {Steps } from "antd";
import {StepsPropsInterface}  from "interface/components"

const { Step } = Steps;
const Profile: React.FC<StepsPropsInterface> = ({steps,...props}) => {
  return(
      <Steps  {...props}>
          {steps.map((step,index)=><Step {...step} key={"step"+index}/>)}
      </Steps>
  )
}
export default Profile