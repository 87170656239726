import React, { useEffect, useState } from "react";
import { Col, Form, Input } from "antd";
import "./Input.less";
import { TypeColorPicker } from "interface/components";

const { Item } = Form;

const ColorPicker: React.FC<TypeColorPicker> = ({classNameLabel = "",label,onErrorLabel,span,theme,className,id,...props}) => {
  
  const [color, setColor] = useState("");
  const classInput = ` custom-input-${theme} ${className} `;
 
  useEffect(()=>{
    setColor(props.value as string?? "")
  },[props.value])
  return (
    <Col span={span} className={classNameLabel}>
      <Item label={label}>
        <label
          htmlFor={id}
          className="btn-color-input"
          style={{ backgroundColor: color }}
        >
          <Input
            {...props}
            type="color"
            id={id}
            className={classInput}
          />
        </label>
        {onErrorLabel && (
          <span className="input-label-error">{onErrorLabel}</span>
        )}
      </Item>
    </Col>
  );
};
export default ColorPicker;
