import React, { useContext } from "react";
import { Typography } from "antd";
import {CustomModal} from 'components/Global'
import { AuthContext } from "components/contexts/UserContext";

interface PropsType {
    openModal:boolean
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
    chatLogo?:string
    color?:string
    name?:string
    userMessageBgColor?:string
    userMessagerColor?:string
    adminMessagerColor?:string
    adminMessageBgColor?:string
    titleColor?:string
    bodyChatBackgroundColor?:string
}



const AppCodeIntegration:React.FC<PropsType> = (
    {
        openModal,
        setOpenModal,
        chatLogo,
color,
name,
userMessageBgColor,
userMessagerColor,
adminMessagerColor,
adminMessageBgColor,
titleColor,
bodyChatBackgroundColor,
    }) => {
  const URL = process.env.REACT_APP_API_BASE_URL;
  const { state } = useContext(AuthContext);
  const Codeblock = `
        <chatbot-component 
        theme-color="${state.user?.chat_user?.color}" 
        logo-image="${URL +""+ chatLogo}" 
        agen-id="454d4d5f4d4fd54f645f" 
        chatbot-title="${state.user?.chat_user?.name}"
        title-color="${titleColor}"
        user-message-bg-color="${userMessageBgColor}"
        user-message-color="${userMessagerColor}"
        admin-message-bg-color="${adminMessageBgColor}"
        admin-message-color="${adminMessagerColor}"
        body-chat-background-color="${bodyChatBackgroundColor}"
        ></chatbot-component>`;
  const CodeScript =
    '<script src="https://page.ma/aion-bootstrap.js?ver=1.1.6" type="text/javascript"></script>';
  return (
    <CustomModal 
        open={openModal}
        onCancel={()=>setOpenModal(false)}
        onOk={()=>setOpenModal(false)}
        className="container-integration">
      <Typography.Title level={2}>Code Sniped</Typography.Title>
      <Typography.Title level={4}>Integration in the body</Typography.Title>
      <Typography.Paragraph copyable className="container-code-block">
        {Codeblock}
      </Typography.Paragraph>
      <Typography.Title level={4}>Integration in the script</Typography.Title>
      <Typography.Paragraph copyable className="container-code-block">
        {CodeScript}
      </Typography.Paragraph>
    </CustomModal>
  );
};

export default AppCodeIntegration;
