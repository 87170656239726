import React, { useContext, useEffect, useState } from "react";
import DashboardContainer from "./DashboardContainer";
import { Column } from "@ant-design/charts";
import { conversationByMonth } from "services/message";
import { AxiosError, AxiosResponse } from "axios";
import { ShepherdTourContext } from "react-shepherd";

const Dashboard: React.FC = () => {
  const [conversations, setConversation] = useState<{ month: string; value: number }[]>([]);
  const tour = useContext(ShepherdTourContext);

  const StartGuidingUser = ()=>{    
    if(!localStorage.getItem("guiding-user")){
      tour?.start()
      localStorage.setItem("guiding-user",true.toString())
    }
  }

  useEffect(() => {
    conversationByMonth()
      .then((response: AxiosResponse) => {
        const dataMapped = Object.entries(response.data)?.map(
          ([month, value]) => {
            const ValuePars = Number(value);
            return { month, value: ValuePars };
          }
        );
        setConversation(dataMapped);
        StartGuidingUser()
      })
      .catch((error: AxiosError) => {
        console.log("error conversation by month :", error.message);
      });
  }, []);

  const config = {
    data: conversations,
    xField: "month",
    yField: "value",
    height: 400,
  };

  return (
    <DashboardContainer>
      <div className="main-container-dashboard">
        <h2> Conversations by month</h2>
        <Column {...config} className="chart-line-conversation" />
      </div>
    </DashboardContainer>
  );
};

export default Dashboard;
