import React from 'react'
import styled from 'styled-components'

export default function logoAionIcon() {
  return (
    <ContainerIcons className='aion-logo-icon'>
        <svg width="51" height="51" viewBox="0 0 72 68" fill="none" xmlns="http://www.w3.org/2000/svg" className='aion-chat-icon'>
          <path d="M47.7489 0H24.4824C11.3944 0 0.747192 10.7305 0.747192 23.9207V31.1308C0.747192 42.904 9.23667 52.7254 20.3703 54.695V51.344L20.9009 49.2942C12.4821 47.6097 6.11502 40.1055 6.11502 31.1308V23.9207C6.11502 13.7072 14.3569 5.40088 24.4912 5.40088H47.7577C57.892 5.40088 66.1339 13.7072 66.1339 23.9207V31.1308C66.1339 41.3444 57.892 49.6507 47.7577 49.6507H33.9358L31.3535 53.786L30.3896 55.0515H47.7665C60.8545 55.0515 71.5017 44.3211 71.5017 31.1308V23.9207C71.484 10.7305 60.8368 0 47.7489 0Z" fill="white"/>
          <path d="M21.8384 67.8763C21.4139 67.8763 20.9806 67.805 20.5472 67.6624C18.9378 67.1098 17.8943 65.6393 17.8943 63.9281V51.3439C17.8943 49.9714 18.9997 48.8574 20.3615 48.8574C21.7234 48.8574 22.8288 49.9714 22.8288 51.3439V60.7553L32.1849 47.7879C32.9896 46.6738 34.5284 46.4332 35.6338 47.2353C36.7392 48.0463 36.9779 49.5971 36.182 50.7111L24.907 66.3345C24.1553 67.3327 23.0234 67.8763 21.8384 67.8763Z" fill="white"/>
          <path d="M36.1731 40.7829C29.6026 40.7829 23.3947 38.8667 18.6901 35.3909C17.4963 34.5086 17.2398 32.8153 18.1153 31.6121C18.9908 30.4089 20.671 30.1505 21.8648 31.0328C29.7618 36.8615 42.6021 36.8615 50.4991 31.0328C51.6929 30.1505 53.3731 30.4089 54.2486 31.6121C55.1241 32.8153 54.8677 34.5086 53.6738 35.3909C48.9516 38.8667 42.7436 40.7829 36.1731 40.7829Z" fill="white"/>
        </svg>

        <svg width="100" height="51" viewBox="0 0 158 46" fill="none" xmlns="http://www.w3.org/2000/svg" className='aion-text-icon'>
            <path d="M44.4758 39.3953C43.5107 39.3953 42.7247 38.6032 42.7247 37.6306V17.2748C42.7247 10.6002 37.3386 5.17196 30.7158 5.17196H15.511C8.88822 5.17196 3.50212 10.6002 3.50212 17.2748V37.6306C3.50212 38.6032 2.71617 39.3953 1.75106 39.3953C0.785955 39.3953 0 38.609 0 37.6306V17.2748C0 8.65486 6.95801 1.64246 15.511 1.64246H30.7158C39.2688 1.64246 46.2268 8.65486 46.2268 17.2748V37.6306C46.2268 38.609 45.4467 39.3953 44.4758 39.3953Z" fill="white"/>
            <path d="M54.8839 39.3953C53.9188 39.3953 53.1328 38.6032 53.1328 37.6305V1.84043C53.1328 0.867783 53.9188 0.0756836 54.8839 0.0756836C55.849 0.0756836 56.6349 0.867783 56.6349 1.84043V37.6305C56.6349 38.609 55.8548 39.3953 54.8839 39.3953Z" fill="white"/>
            <path d="M91.0782 1.64246H75.8735C67.3204 1.64246 60.3624 8.65486 60.3624 17.2748V21.9866C60.3624 29.6804 65.9103 36.0988 73.1862 37.3859V35.196L73.5329 33.8564C68.0313 32.7556 63.8703 27.8516 63.8703 21.9866V17.2748C63.8703 10.6002 69.2564 5.17196 75.8793 5.17196H91.084C97.7068 5.17196 103.093 10.6002 103.093 17.2748V21.9866C103.093 28.6612 97.7068 34.0894 91.084 34.0894H82.0513L80.3638 36.7919L79.7339 37.6189H91.0898C99.6428 37.6189 106.601 30.6065 106.601 21.9866V17.2748C106.589 8.65486 99.6313 1.64246 91.0782 1.64246Z" fill="white"/>
            <path d="M74.1456 46C73.8682 46 73.585 45.9534 73.3018 45.8603C72.25 45.4991 71.5681 44.5381 71.5681 43.4199V35.196C71.5681 34.2991 72.2905 33.5711 73.1805 33.5711C74.0705 33.5711 74.7928 34.2991 74.7928 35.196V41.3465L80.9071 32.8722C81.433 32.1441 82.4386 31.9869 83.1609 32.5111C83.8833 33.0411 84.0394 34.0545 83.5193 34.7825L76.1509 44.9924C75.6597 45.6448 74.92 46 74.1456 46Z" fill="white"/>
            <path d="M83.5134 28.2942C79.2196 28.2942 75.1627 27.042 72.0882 24.7705C71.308 24.1939 71.1404 23.0873 71.7125 22.3011C72.2847 21.5148 73.3827 21.3459 74.1629 21.9225C79.3236 25.7315 87.7148 25.7315 92.8755 21.9225C93.6557 21.3459 94.7537 21.5148 95.3259 22.3011C95.898 23.0873 95.7304 24.1939 94.9502 24.7705C91.8642 27.042 87.8073 28.2942 83.5134 28.2942Z" fill="white"/>
            <path d="M44.0713 22.3011H2.39832V25.8306H44.0713V22.3011Z" fill="white"/>
            <path d="M113.524 39.3196C112.559 39.3196 111.773 38.5275 111.773 37.5548V3.3373C111.773 2.66751 112.149 2.05596 112.744 1.75893C113.339 1.46189 114.05 1.52596 114.582 1.92783L154.504 32.3072V1.76475C154.504 0.792099 155.29 0 156.255 0C157.22 0 158.006 0.792099 158.006 1.76475V35.8483C158.006 36.5181 157.63 37.1297 157.035 37.4267C156.44 37.7237 155.729 37.6597 155.197 37.2578L115.275 6.87845V37.5548C115.275 38.5333 114.489 39.3196 113.524 39.3196Z" fill="white"/>
        </svg>
    </ContainerIcons>
  )
}

const ContainerIcons = styled.div`
  display: flex;
  gap: 6px;
  `
