import { AoinLoading } from "components/Global";
import { AuthContext } from "components/contexts/UserContext";
import React, { PropsWithChildren, useContext } from "react";
import { Navigate } from "react-router-dom";
import {Urls} from "Constants"
const RequireAuth: React.FC<PropsWithChildren> = ({ children }) => {
  const { state } = useContext(AuthContext);  
  if(!state.user && state.token){
    return <AoinLoading />
  }
  return !state.user ? <Navigate to={Urls.login} replace /> : <>{children}</>;
};

export default RequireAuth;
