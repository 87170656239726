import React, { ReactNode } from "react";
import "./messageCard.less";
import moment from "moment";
import { Avatar, Button, Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import Tags from "../../../containers/Intent/Tags";

interface PropsType {
  id?: string;
  title?: ReactNode;
  description?: string | ReactNode;
  time?: string;
  theme?: "primary" | "secondary";
  onclick?: (id?: string) => void;
  avatar?: string;
  messageHelp?: string;
  className?:string
  tags?:any
}

const MessageCard: React.FC<PropsType> = ({
  id,
  onclick,
  description,
  time,
  title,
  theme = "primary",
  avatar,
  messageHelp,
  className="",
  tags,
}) => {
  return (
    <div
      className={`container-message-card ${theme} ${className}`}
      onClick={() => {
        onclick && onclick(id);
      }}
    >
      <div className="container-header-message-card">
        {avatar && (
          <Avatar
            icon={avatar?.substring(0, 2)?.toUpperCase()}
            className="container-avatar"
          />
        )}
        <h3 className="message-card-title">{title}</h3>
        <p className="message-card-time">
          {time && moment(time).format("YYYY-MM-DD hh:mm")}
        </p>
      </div>
      {messageHelp && (
        <div className="container-btn-help">
          <Popover
            content={messageHelp}
            trigger="hover"
            overlayClassName="popup-help"
            placement="bottomRight"
          >
            <Button className="btn-help">
              <InfoCircleOutlined />
            </Button>
          </Popover>
        </div>
      )}
      <p className="description-message-card">{description}</p>
        {tags && <Tags data={tags} />}
    </div>
  );
};
export default MessageCard;
