import React, { useEffect, useState } from "react";
import { DashboardContainer } from "../Dashboard";
import "./intent.less";
import { MessageCard } from "components/Global";
import { getIntents } from "../../services/message";
import { AxiosResponse } from "axios";
import DrawerIntent from "./DrawerIntent";
import {  ReloadOutlined } from "@ant-design/icons";
const Intent: React.FC = () => {
  const [intents, setIntents] = useState<any>([]);
  const [idIntent, setIdIntent] = useState<null | number>(null);
  const [countIntentDisplay, setCountIntentDisplay] = useState<number>(5);

  useEffect(() => {
    getIntents()
      .then((data: AxiosResponse) => {
        console.log('data :: ::::',data.data)
        setIntents(data.data);
      })
      .catch(() => {
        console.log("error get intents");
      });
  }, []);

  return (
    <DashboardContainer>
      <div className="">
        <div className="container-header">
          <h1>Intents configurations</h1>
        </div>
        <div className="container-intents">
          {(intents as any[])?.slice(0, countIntentDisplay).map((intent: any) => (
              <MessageCard
                  title={intent.name}
                  description={intent.desc}
                  id={intent.id}
                  key={intent.id}
                  onclick={(id) => id && setIdIntent(Number(id))}
                  theme="secondary"
                  messageHelp="help me"
                  tags={intent.tags}
              />
          ))}
          {countIntentDisplay < intents.length &&<MessageCard
              description={<ReloadOutlined />}
              className="load-more-card"
              onclick={() => setCountIntentDisplay((prev) => prev + 3)}
              theme="secondary"
          />}
          <DrawerIntent idIntent={idIntent} setIdIntent={setIdIntent} />
        </div>

      </div>
    </DashboardContainer>
  );
};

export default Intent;
