import React from 'react'

export default function ProfileIcon() {
  return (
    <svg width="30" height="39" viewBox="0 0 30 39" fill="none" xmlns="http://www.w3.org/2000/svg" className="aion-profile-icon">
      <path d="M13.32 38.0499C12.66 37.9999 12 37.9599 11.33 37.8999C8.62003 37.6599 5.96003 37.2299 3.48003 36.0199C2.54003 35.5599 1.66003 34.9999 1.01003 34.1599C0.880027 33.9899 0.780029 33.7499 0.780029 33.5499C0.810029 31.4099 0.820027 29.2699 1.19003 27.1499C1.46003 25.5799 1.94003 24.0999 3.07003 22.9099C3.86003 22.0799 4.82003 21.5199 5.87003 21.0899C7.40003 20.4599 9.00003 20.1099 10.62 19.8099C10.72 19.7899 10.88 19.8499 10.96 19.9199C11.9 20.7899 13.03 21.1799 14.28 21.2799C15.53 21.3799 16.74 21.2399 17.86 20.6199C18.22 20.4199 18.52 20.1399 18.86 19.9099C18.96 19.8499 19.1 19.7799 19.2 19.7999C20.85 20.0999 22.49 20.4599 24.05 21.1199C25.55 21.7499 26.82 22.6499 27.63 24.0999C28.23 25.1699 28.58 26.3299 28.68 27.5399C28.85 29.5299 28.94 31.5199 29.03 33.5099C29.04 33.7199 28.94 33.9899 28.8 34.1599C27.95 35.2299 26.78 35.8699 25.54 36.3799C23.26 37.3199 20.86 37.7099 18.41 37.8999C17.8 37.9499 17.18 37.9999 16.57 38.0399C15.5 38.0499 14.41 38.0499 13.32 38.0499Z" fill="white"/>
      <path d="M7.46 9.30993C7.49 7.91993 7.59999 6.53991 8.02999 5.19991C8.81999 2.71991 10.56 1.40991 13.08 1.03991C14.63 0.809909 16.16 0.839911 17.68 1.22991C19.7 1.74991 21.1 2.98993 21.72 4.96993C22.57 7.70993 22.73 10.5099 21.87 13.2899C21.14 15.6299 19.76 17.5099 17.45 18.5199C15.27 19.4699 13.17 19.2099 11.23 17.8699C9.25 16.4999 8.15999 14.5199 7.68999 12.2099C7.49999 11.2699 7.47 10.2999 7.37 9.33993C7.4 9.31993 7.43 9.30993 7.46 9.30993Z" fill="white"/>
    </svg>
    
  )
}
