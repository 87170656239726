import React from 'react'

export default function AnalyticsIcon() {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" className='aion-analytics-icon'>
      <path d="M14.6399 37.92C13.8399 37.61 13.5999 37.01 13.6099 36.18C13.6299 24.89 13.6199 13.61 13.6199 2.32001C13.6199 1.44001 14.0099 0.910029 14.7299 0.780029C14.8699 0.760029 15.0199 0.76001 15.1599 0.76001C17.6599 0.76001 20.1699 0.76001 22.6699 0.76001C23.7199 0.76001 24.2199 1.27001 24.2199 2.32001C24.2199 13.61 24.2099 24.89 24.2299 36.18C24.2299 37.01 23.9899 37.61 23.1999 37.92C20.3499 37.92 17.4899 37.92 14.6399 37.92Z" fill="white"/>
      <path d="M27.9199 37.9201C27.1099 37.6201 26.8699 37.0201 26.8799 36.1901C26.8999 28.4701 26.8899 20.7601 26.8899 13.0401C26.8899 12.8201 26.8799 12.6001 26.9299 12.3901C27.0599 11.7801 27.5799 11.3801 28.2699 11.3801C30.8799 11.3701 33.4999 11.3701 36.1099 11.3801C36.9699 11.3801 37.5099 11.9501 37.5099 12.8101C37.5099 20.7101 37.5099 28.6101 37.5099 36.5101C37.5099 37.2401 37.1099 37.66 36.4899 37.93C33.6299 37.92 30.7799 37.9201 27.9199 37.9201Z" fill="white"/>
      <path d="M0.329947 16.36C0.659947 15.56 1.27995 15.32 2.11995 15.34C4.53995 15.37 6.95995 15.35 9.36995 15.35C10.4399 15.35 10.9399 15.85 10.9399 16.93C10.9399 23.36 10.9299 29.79 10.9499 36.22C10.9499 37.04 10.6999 37.62 9.90995 37.92C7.04995 37.92 4.19995 37.92 1.33995 37.92C0.859947 37.72 0.519946 37.39 0.319946 36.9C0.329946 30.05 0.329947 23.21 0.329947 16.36Z" fill="white"/>
    </svg>

  )
}
