import { AuthContext } from "components/contexts/UserContext";
import { Navigate } from "react-router-dom";
import { RequireAuth } from "Middleware";
import React, { useContext } from "react";
import {Urls} from "Constants"

const RequireNotCompleteInscription: React.FC<React.PropsWithChildren> = ({children}) => {
  const { state } = useContext(AuthContext);
  return (
    <RequireAuth>
      {state.user?.inscription_step === 3 ? <Navigate to={Urls.dashboard} replace /> : children }
    </RequireAuth>
  );
};

export default RequireNotCompleteInscription;
