import React from "react";
import { Col, Form, Input } from "antd";
import { TypeInputText } from "../../../interface/components";
import "./Input.less";

const { Item } = Form;

const InputText: React.FC<TypeInputText> = ({
  position = "left",
  theme = "default",
  className = "",
  classNameLabel="",
  span = 24,
  rules,
  label,
  onError,
  ...props
}) => {
  let classContainer = "";
  if (span !== 24) {
    classContainer =
      position === "left" ? ` custom-container-input-inline-left` : "";
  }
  const classInput = ` custom-input-${theme} ${className} `;
  return (
    <Col span={span} className={classContainer + classNameLabel}>
      <Item label={label} rules={rules}>
        <Input className={classInput} {...props} size="large" />
        {onError && <span className="input-label-error">{onError}</span>}
      </Item>
    </Col>
  );
};
export default InputText;
