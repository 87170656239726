import React from "react";
import { Col, DatePicker, Form } from "antd";
import "./DatePicker.less";
import {PropsTypeDatePicker} from "interface/components"
const { Item } = Form;

const InputDatePicker: React.FC<PropsTypeDatePicker> = ({
  theme = "default",
  className = "",
  classNameLabel="",
  span = 24,
  rules,
  label,
  onError,
  ...props
}) => {
  const classInput = `custom-input-${theme}`;
  return (
    <Col span={span} className={classNameLabel}>
    <Item label={label} rules={rules}>
        <DatePicker
            {...props}
            className={`full-width ${classInput} ${className}`}
        />
        {
            onError && (
                <span style={{color: "#f15930"}}>{onError}</span>
            )
        }
    </Item>
</Col>
  );
};
export default InputDatePicker;
