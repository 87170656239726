import { ShepherdTour } from "react-shepherd";
import React, { PropsWithChildren } from "react";
import "./GuidingUser.less";
import { Menu, PropsShepherdTour } from "Constants/MessageGuidingUser";

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: false,
    },
  },
  useModalOverlay: true,
};



const GuidingUserApp: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ShepherdTour
      steps={[
        {
          id: "home",
          attachTo: { element: ".ant-menu-item:nth-child(1)", on: "right" },
          title: Menu.home.title,
          text: [Menu.home.text],
          ...PropsShepherdTour("first"),
          arrow:true
        },
        {
          id: "intents",
          attachTo: { element: ".ant-menu-item:nth-child(2)", on: "right" },
          title: Menu.intents.title,
          text: [Menu.intents.text],
          ...PropsShepherdTour(),
        },
        {
          id: "message",
          attachTo: { element: ".ant-menu-item:nth-child(3)", on: "right" },
          title: Menu.message.title,
          text: [Menu.message.text],
          ...PropsShepherdTour(),
        },
        {
          id: "planning",
          attachTo: { element: ".ant-menu-item:nth-child(4)", on: "right" },
          title: Menu.planning.title,
          text: [Menu.planning.text],
          ...PropsShepherdTour(),
        },
        {
          id: "chatBotSettings",
          attachTo: { element: ".ant-menu-item:nth-child(6)", on: "bottom" },
          title: Menu.chatBotSettings.title,
          text: [Menu.chatBotSettings.text],

          ...PropsShepherdTour(),
        },
        {
          id: "Notification",
          attachTo: {
            element:
              ".ant-page-header-heading-extra .ant-space-item:nth-child(2)",
            on: "bottom",
          },
          title: Menu.notification.title,
          text: [Menu.notification.text],
          ...PropsShepherdTour("last"),
        },

        
      ]}
      tourOptions={tourOptions}
    >
      {children}
    </ShepherdTour>
  );
};

export default GuidingUserApp;
