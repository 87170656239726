import React from "react";
import { Tag } from "antd";

interface PropsType {
    data: any;
}
const Tags: React.FC <PropsType> = ({
        data
    }) => {
  return (
    <div className="tags">
      {data && data.map((tag: any) => (
        <Tag
            className="tag"
            key={tag?.id}
            color={tag?.color}
        >
            {tag?.name}
        </Tag>
      ))}
    </div>
  );
}

export default Tags;