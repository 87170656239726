import { Layout } from 'antd';
import React, {PropsWithChildren, ReactNode} from 'react';

const { Content, Footer } = Layout;

type Props = PropsWithChildren & {
    header?: ReactNode
};

const FullWidthLayout: React.FC<Props> = ({children, header}) => {
    return (
        <Layout style={{minHeight: "100vh"}}>
            {header ? header:(<></>) }
            <Content style={{backgroundColor: '#E5E5E5', padding:'0 50px'}}>{children}</Content>
            <Footer style={{backgroundColor: '#E5E5E5', padding:'0 50px'}}>Footer</Footer>
        </Layout>
    );
}

export default FullWidthLayout;