import api from "utils/interceptorHandler"


export const getSession = () => {
    return api.get("/api/workflow/conversasion_session/conversation_by_user")
}

export const getMessage = (conversation_id:string) => {
    return api.post("/api/workflow/conversasion_session/message_by_conversation", {conversation_session_id:conversation_id})
}
export const conversationByMonth = () =>{
    return api.get(`/api/workflow/conversasion_session/conversation-by-month`)
}
export const getIntents =  () => {
    return api.get("/api/workflow/intent/list/")
}

export const getIntentsDetails =  (id: number) => {
    return api.get(`/api/workflow/intent/conversation-by-user?id_intent=${id}`)
}

export const updateEntenteMessage =  (data: any) => {
    return api.post('/api/workflow/intent/save-response', {data})
}

export const getAppointment =  () => {
    return api.get(`/api/workflow/conversasion_session/conversation-with-appointment`)
}

export const getNotifications = () => {
    return api.get(`/api/get-notifications/`)
}

export const FetchSaveNotificationReaded = (id: [string]) => {
    return api.post(`/api/save-is-read/`, {data: id})
}
export const saveMessageReaded = (id: [string]) => {
    return api.post(`/api/save-is-waiting-true/`, {data: id})
}



