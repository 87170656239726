import React, { useContext, useEffect, useState } from "react";
import { DashboardContainer } from "containers/Dashboard";
import "./ChatBotSettings.less";
import { AuthContext } from "components/contexts/UserContext";
import { ChatUser } from "interface/services";
import { fetchSaveInfo, fetchTagsInfo } from "services/user";
import { AxiosError, AxiosResponse } from "axios";
import {message, Select} from "antd";
import * as validation from "Constants/validation";
import * as yup from "yup";
import { Formik } from "formik";
import CodeIntegration from './CodeIntegration'
import {
  ColorPicker, CustomFileInput,
  CustomTextArea,
  InputButton,
  InputText,
} from "components/Global";
import { MessageInfoSuccessEdit } from "Constants";
import PreviewChatBot from "./PreviewChatBot";
import {validationStringRequire, validationColor} from "Constants/validation";

const ChatBotSettingsApp: React.FC = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [openModal,setOpenModal] = useState(false)
  const [initialValue, setInitialValue] = useState<ChatUser>({
    color: "#0066CC",
    desc: "",
    host: "",
    name: "my assistant",
    logo: "",
    admin_messager_bg_color:"#d9d9d9",
    admin_messager_color:"#000000",
    user_messager_color:"#333333",
    user_message_bg_color:"#cdc5c5",
    title_color:"#000000",
    body_chat_background_color:"#f2f2f2",
      // services: [],  // not working
  });

    interface servicesPropsType {
        id: number;
        name: string;
        color: string;
        description: string;
    }

  const [chatLogo, setChatLogo] = useState<any>(state.user?.chat_user);
  const [color, setColor] = useState<string>("");
  const [colorTitle, setColorTitle] = useState<string>("");
  const [colorBodyChat, setColorBodyChat] = useState<string>("");
    const [colorAdminMessagerBg, setColorAdminMessagerBg] = useState<string>("");
    const [colorAdminMessager, setColorAdminMessager] = useState<string>("");
    const [colorUserMessager, setColorUserMessager] = useState<string>("");
    const [colorUserMessageBg, setColorUserMessageBg] = useState<string>("");
    const [ServicesType, setServicesType] = useState<any>(null);
  const validationForm = yup.object().shape({
    color: validation.validationStringRequire,
    desc: validation.validationStringRequire,
    host: validation.validationStringRequire,
    name: validation.validationStringRequire,
    logo: validation.validationStringRequire,
    admin_messager_bg_color: validationColor,
    admin_messager_color: validationColor,
    user_messager_color: validationColor,
    user_message_bg_color: validationColor,
    title_color: validationStringRequire,
    body_chat_background_color: validationColor,
  });

  useEffect(() => {
   state.user?.chat_user && setInitialValue(state.user?.chat_user);
    setChatLogo(state.user?.chat_user?.logo)
    setColor(state.user?.chat_user?.color ?? "")
    setColorTitle(state.user?.chat_user?.title_color ?? "")
    setColorBodyChat(state.user?.chat_user?.body_chat_background_color ?? "")
    setColorAdminMessagerBg(state.user?.chat_user?.admin_messager_bg_color ?? "")
    setColorAdminMessager(state.user?.chat_user?.admin_messager_color ??"")
    setColorUserMessager(state.user?.chat_user?.user_messager_color ?? "" )
    setColorUserMessageBg(state.user?.chat_user?.user_message_bg_color ?? "")
  }, []);

    useEffect(() => {
        fetchTagsInfo().then((response: AxiosResponse) => {
            console.log("list data tags ==>",response.data)
            setServicesType(response.data);
        })
    }, []);

  const HandelSubmitRegister = (e: ChatUser) => {
    let logo: any = e.logo
    if(state.user?.chat_user?.logo === e?.logo){
        logo = undefined
    }
      console.log("e",e)
    fetchSaveInfo({ ...e, service: e.desc, logo })
      .then((response: AxiosResponse) => {
          console.log("e",e)
        dispatch({
          type: "SET_USER",
          payload: {
            user: { ...state.user, chat_user: e },
            refreshToken: null,
            token: null,
          },
        });
        message.open({content:MessageInfoSuccessEdit,type:"success"});
        setChatLogo(response?.data?.logo)
        setColor(response?.data?.color ?? "")
        setColorTitle(response?.data?.title_color ?? "")
        setColorBodyChat(response?.data?.body_chat_background_color ?? "")
        setColorAdminMessagerBg(response?.data?.admin_messager_bg_color ?? "")
        setColorAdminMessager(response?.data?.admin_messager_color ??"")
        setColorUserMessager(response?.data?.user_messager_color ?? "" )
        setColorUserMessageBg(response?.data?.user_message_bg_color ?? "")

      })
      .catch((error: AxiosError) => {
        console.log("error", error);
      });
  };

  const handleFileChange = (info: any) => {
    // Handle file change logic here
    console.log('File change:', info);
  };
  return (
    <DashboardContainer>
      <div className="content-chat-bot-setting">
          <h1 className={"heading"}>Chatbot Settings</h1>
        <Formik
          initialValues={initialValue}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationForm}
          onSubmit={HandelSubmitRegister}
        >
          {(formik) => (
            <div className={"container-settings"}>

              <div className="container-intent-configuration">
              <form  onSubmit={formik.handleSubmit} >
                <div className="ant-form ant-form-vertical content-intent-configuration">
                  <div className="container-inputs">
                    <h2 className="heading">
                      Chatbot Settings
                    </h2>
                    <InputText
                      label="Website name : *"
                      placeholder="example"
                      theme="secondary"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onError={formik.errors?.name}
                      classNameLabel="custom-label-style"
                    />
                    <InputText
                      label="Name Domain : *"
                      placeholder="example.com"
                      theme="secondary"
                      name="host"
                      value={formik.values.host}
                      onChange={formik.handleChange}
                      onError={formik.errors?.host}
                      classNameLabel="custom-label-style"
                    />
                    <CustomTextArea
                      theme="secondary"
                      label="Service  : *"
                      placeholder="Enter description about Services your website"
                      classNameLabel="custom-label-style"
                      rows={7}
                      name="desc"
                      value={formik.values.desc}
                      onChange={formik.handleChange}
                      onErrorLabel={formik.errors?.desc}
                    />
                    <CustomFileInput
                        onChange={handleFileChange}
                        label="Upload Logo :"
                        //classNameLabel="custom-label-style"
                        name={"logo"}
                        formik={formik}
                        accept="image/*"

                    />
                      {/*<Select*/}
                      {/*    mode={"multiple"}*/}
                      {/*    className="select-tags"*/}
                      {/*    placeholder="Please select"*/}
                      {/*    style={{ width: '100%' }}*/}
                      {/*    onChange={(value:any)=>formik.setFieldValue("services",value)}*/}
                      {/*    value={formik.values.services}*/}
                      {/*    options={ServicesType?.map((service: servicesPropsType) => ({ label: service.name, value: service.id }))}*/}
                      {/*/>*/}
                    <ColorPicker
                        label="Selectionner la coleur global :"
                        classNameLabel="custom-label-style"
                        name="color"
                        id={"color"}
                        value={formik.values.color}
                        onChange={formik.handleChange}
                        onErrorLabel={formik.errors?.color}
                    />
                    <ColorPicker
                        label="Selectionner la couleur de titre :"
                        classNameLabel="custom-label-style"
                        name="title_color"
                        value={formik.values.title_color}
                        id="title_color"
                        onChange={(e)=>{formik.setFieldValue("title_color",e.target.value)}}
                        onErrorLabel={formik.errors?.title_color}
                    />
                    <ColorPicker
                        label="Selectionner la couleur arriére plan de messagerie :"
                        classNameLabel="custom-label-style"
                        name="body_chat_background_color"
                        value={formik.values.body_chat_background_color}
                        id="body_chat_background_color"
                        onChange={(e)=>{formik.setFieldValue("body_chat_background_color",e.target.value)}}
                        onErrorLabel={formik.errors?.body_chat_background_color}
                    />

                    <ColorPicker
                        label="Selectionner la couleur arriére plan pour messagerie admin :"
                        classNameLabel="custom-label-style"
                        name="admin_messager_bg_color"
                        value={formik.values.admin_messager_bg_color}
                        id="admin_messager_bg_color"
                        onChange={(e)=>{formik.setFieldValue("admin_messager_bg_color",e.target.value)}}
                        onErrorLabel={formik.errors?.admin_messager_bg_color}
                    />

                    <ColorPicker
                        label="Selectionner la couleur de texte de messagerie admin :"
                        classNameLabel="custom-label-style"
                        name="admin_messager_color"
                        value={formik.values.admin_messager_color}
                        id="admin_messager_color"
                        onChange={(e)=>{formik.setFieldValue("admin_messager_color",e.target.value)}}
                        onErrorLabel={formik.errors?.admin_messager_color}
                    />
                    <ColorPicker
                        label="Selectionner la couleur de texte de messagerie utlisateur :"
                        classNameLabel="custom-label-style"
                        name="user_messager_color"
                        value={formik.values.user_messager_color}
                        id="user_messager_color"
                        onChange={(e)=>{formik.setFieldValue("user_messager_color",e.target.value)}}
                        onErrorLabel={formik.errors?.user_messager_color}
                    />
                    <ColorPicker
                        label="Select Coleur de l'arriere plan de messagerie utilisateur :"
                        classNameLabel="custom-label-style"
                        name="user_message_bg_color"
                        value={formik.values.user_message_bg_color}
                        id="user_message_bg_color"
                        onChange={(e)=>{formik.setFieldValue("user_message_bg_color",e.target.value)}}
                        onErrorLabel={formik.errors?.user_message_bg_color}
                    />

                  </div>
                </div>

                <InputButton
                  type="primary"
                  className="btn-lg btn-next"
                  htmlType="submit"
                >
                  Edit
                </InputButton>
                <InputButton
                  block
                  type="default"
                  className="btn-lg btn-next btn-code-sniped"
                  onClick={(e)=>{
                    e.preventDefault()
                    setOpenModal(true)
                  }}
                >
                  Sniped code
                </InputButton>
              </form>
              </div>
              <div className="preview-section">
                <PreviewChatBot
                    color={formik.values.color}
                    userMessageBgColor={formik.values.user_message_bg_color}
                    name={formik.values.name}
                    chatLogo={chatLogo}
                    userMessagerColor={formik.values.user_messager_color}
                    adminMessagerColor={formik.values.admin_messager_color}
                    adminMessageBgColor={formik.values.admin_messager_bg_color}
                    titleColor={formik.values.title_color}
                    bodyChatBackgroundColor={formik.values.body_chat_background_color}
                />
              </div>
            </div>
          )}
        </Formik>
        <CodeIntegration
            openModal={openModal}
            chatLogo={chatLogo}
            setOpenModal={setOpenModal}
            color={color}
            userMessageBgColor={colorUserMessageBg}
            userMessagerColor={colorUserMessager}
            adminMessagerColor={colorAdminMessager}
            adminMessageBgColor={colorAdminMessagerBg}
            titleColor={colorTitle}
            bodyChatBackgroundColor={colorBodyChat}
        />
      </div>
    </DashboardContainer>
  );
};

export default ChatBotSettingsApp;
