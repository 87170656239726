export const EmptyNotificationMessage = "You currently have no notifications.";
export const allFieldsRequired = "All Fields Required ";
export const MessageImportantCheckedMessage ="You need to check this conversation!!";
export const MessageSuccessRegister ="Your Account Created successfully you can login now";
export const MessageInfoSuccessEdit ="Data has been edited successfully.";
export const MessageErrorRequire = "This field is required";
export const IntentSaveSuccess = "Intent save successfully";

export const StringError = (chomp: string) => `Enter valid ${chomp}`;

export const Urls = {
  login: "/login",
  signUp: "/sign-up",
  planning: "/dashboard/planning",
  messages: "/dashboard/messages",
  intent: "/dashboard/intent",
  dashboard: "/dashboard/",
  setup: "/setup",
  home: "/",
  profile: "/dashboard/profile",
  integration: "/dashboard/integration",
  chatBotSettings: "/dashboard/chat-bot-settings",
};
