
import * as yup from "yup";
import {MessageErrorRequire,StringError} from './index'

//Regex
const regexEmail = /^([A-Za-z0-9]+[.-_])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+$/;
const regexNumber = /^[0-9]+$/;
const regexName = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/;
const regexPhone = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
const regexDomainName = /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/

// validation variable
const validationName =(champ:string) => yup
  .string()
  .matches(regexName, StringError(champ))
  .min(3, StringError(champ))
  .max(100, StringError(champ));
const validationEmail = yup
  .string()
  .email(StringError("email"))
  .matches(regexEmail, StringError("email"))
  .max(254, StringError("email"));
const validationPhone = yup
  .string()
  .matches(regexPhone,StringError("phone number"))
  .max(20, StringError("phone number"));

const validationAddressLine = yup
  .string()
  .max(200,StringError("phone number"));

const validationDate = yup.date().required(MessageErrorRequire);
const validationStringRequiredNullable = yup
  .string()
  .required(MessageErrorRequire)
  .nullable();
const validationStringMaxString = (champ:string) => yup.string().max(100,StringError(champ));

const validationNumber =(champ:string)=>yup
    .string()
    .matches(regexNumber, StringError(champ))
const validationDomainName =yup
    .string()
    .matches(regexDomainName, StringError("domain name"))
const validationFileRequired = yup

const validationColor = yup.string().matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, StringError("color")).nullable(true)
const validationStringRequire = yup.string().required(MessageErrorRequire)
const validationHostRequire = yup.string().test('is-valid-url', 'Invalid URL format. Please enter a valid URL without http:// or https:// ', (value) => {
        if (!value) {
            return false; // Empty URL is invalid
        }
        const urlRegex = /^(?!\/\/|http:|https:).*/;
        return urlRegex.test(value);
    }).required('URL is required');
export{
    validationStringRequire,
    validationDomainName,
    validationName,
    validationEmail,
    validationPhone,
    validationAddressLine,
    validationDate,
    validationStringRequiredNullable ,
    validationStringMaxString,
    validationNumber,
    validationFileRequired,
    validationHostRequire,
    validationColor
}