import React, { useEffect, useState } from "react";
import { PricingCards } from "components/Global";
import { AxiosError, AxiosResponse } from "axios";
import { getPaymentInitiate, getPaymentOffers } from "services/user";
const AppBillingInformation = () => {
  const [paymentOffers, setPaymentOffers] = useState<any[]>([]);


  useEffect(() => {
    getPaymentOffers()
      .then((response: AxiosResponse) => {
        const responseOffers = response.data.map((paymentOffer: any) => {
              return {
                id: paymentOffer.id as string,
                description: paymentOffer.description as string,
                trial: paymentOffer.price as string,
                title: paymentOffer.name as string,
                onclick: ()=>{
                    getPaymentInitiate(paymentOffer.id)
                    .then((response: AxiosResponse) => {
                            window.open(response?.data?.redirect_url, "_blank")
                    }).catch((err: AxiosError) => {
                        console.log("Error get Payment Initiate", err);
                    });
                }
            }

        }

        );
        setPaymentOffers( responseOffers);

        
      })
      .catch((error: AxiosError) => {
        console.log("Error getPaymentOffers", error.message);
      });
  }, []);


  return (
    <div className="container-payments-information">
      <div>
        <h1 className="title-page-payment">Choose Your Plan</h1>
        <PricingCards cards={paymentOffers} />
      </div>
    </div>
  );
};
export default AppBillingInformation;
