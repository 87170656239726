import { Alert } from "antd";
import React, { useMemo, useState } from "react";
import { TypeAlert } from "interface/components";

const FlashMessage: React.FC<TypeAlert> = ({duration,sessionsName,...props}) => {
  const [flashMessage, setFlashMessage] = useState(localStorage.getItem(sessionsName) ?? null);
  useMemo(() => {
    if (flashMessage) {
      const timeout = setTimeout(() => {
        setFlashMessage(null);
        localStorage.removeItem(sessionsName);
      }, duration);
      return () => clearTimeout(timeout);
    }
  }, [flashMessage]);
  return (
    <>
     { flashMessage &&<Alert message={flashMessage}  {...props} />}
    </>
  );
};

export default FlashMessage;
