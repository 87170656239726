import {DataSaveInfo, UserInterface} from "interface/services"
import api from "utils/interceptorHandler"
import {apiWithoutToken} from "utils/api"
export const loginHandler = (email:string,password:string)=> api.post('/api/auth/login/',{email,password})

export const fetchCurrentUser = ()=> api.get("/api/account/me/")

export const getInfoSetupIntent = () => api.get("/api/get-info/")

export const getPaymentOffers = () => api.get("/api/payment/offers/")

export const getPaymentInitiate = (offer_id:string) => api.post("/api/payment/initiate/",{offer_id})

export const fetchRegistration = (data:UserInterface) => apiWithoutToken.post("/api/auth/registration/",data)

export const fetchSaveInfo = (data:DataSaveInfo) => {
        console.log("befor",data)
    const response = api.put(`/api/workflow/chat/${data.id}/update`, {...data},{headers:{"Content-Type":"multipart/form-data"}} )
        console.log("after",response.then((res)=>console.log(res)))
    return response
    }

export const fetchCreateInfo = (data:DataSaveInfo) => api.post("/api/workflow/chat/new/",data)

export const fetchSaveSteps = (step:number) => api.post("/api/save-steps/",{step})

export const fetchTagsInfo = () => api.get("/api/workflow/chat-type/list/")


