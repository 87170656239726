import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";
import "./fullCalendar.less";
import {FullCalendarProps} from "interface/components"



const CalendarApp:React.FC<FullCalendarProps> = ({onDateRangChanged,...props}) => {
  const RefFullCalendar = React.useRef<FullCalendar>(null!);

  const propsCalendar = {
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
    initialView: "timeGridWeek",
    slotDuration: "02:00:00",
    locale: frLocale,
    eventColor: "#EFB521",
    allDaySlot: false,
    dayMaxEvents: true,
    headerToolbar: {
      left: "dayGridMonth,timeGridWeek,timeGridDay",
      center: "",
      right: "today,prev,title,next",
    },
  };

  return (
    <div className="container-calendar">
      <FullCalendar
        datesSet={(info) => onDateRangChanged && onDateRangChanged(info)}
        ref={RefFullCalendar}
        {...propsCalendar}
        titleFormat={{ day: "2-digit", month: "short" }}
        eventTimeFormat={{ hour: "numeric", minute: "2-digit", hour12: false }}
        slotLabelFormat={{ hour: "2-digit", minute: "2-digit" }}
        buttonText={{ day: "Jour", week: "Semaine", month: "Mois" }}
        {...props}
      />
    </div>
  );
};

export default CalendarApp;
