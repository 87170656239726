import React, {
  useEffect,
  createContext,
  useState,
  PropsWithChildren,
  useMemo,
  useContext,
} from "react";
import { NotificationInterface, ContextNotification } from "interface/services";
import { AuthContext } from "./UserContext";
import { MessageImportantCheckedMessage } from "Constants";

import { getNotifications } from "services/message";
import {AxiosError, AxiosResponse} from "axios";

export const NotificationContext = createContext<ContextNotification>({
  notifications: [],
  setNotifications: null,
});

const NotificationContextProvider: React.FC<PropsWithChildren> = ({children}) => {
  const { state } = useContext(AuthContext);
  const [webSocket, setWebSocket] = useState<any>();
  const [notifications, setNotifications] = useState<NotificationInterface[]>(
    []
  );

  useEffect(() => {
    getNotifications().then((response: AxiosResponse) => {
      console.log("response get notifications :::::==> ", response.data);
      const info = response.data.map((item: any) => {
        return {
          message: item?.message,
          username: item?.username,
          conversation_code: item?.conversation_code,
          conversation_email: item?.email,
          id: item?.id
        };
      })
      console.log("info", info)
      setNotifications(info);
    }).catch((error: AxiosError) => {
      console.log("error get notifications", error);
    })
    ;
  }, []);
  useEffect(() => {    
    if (state.user?.email) {
      setWebSocket(new WebSocket(`${process.env.REACT_APP_SOCKET_BASE_URL}/notify/?email=${state.user?.email}`));

    } else {
      setWebSocket(null);
      setNotifications([]);
    }
  }, [state.user?.email]);

  useMemo(() => {
    if (webSocket) {      
      webSocket.onopen = () => console.log("connected",`${process.env.REACT_APP_SOCKET_BASE_URL}/notify/?email=${state.user?.email}`);
      
      webSocket.onclose = () => console.log("disconnected");

      webSocket.onmessage = (event: any) => {
        const response = JSON.parse(event.data);
        console.log(response.message !== MessageImportantCheckedMessage,"or",response.message === MessageImportantCheckedMessage,"and",!notifications.some(n => n.conversation_code === response.conversation_code));
        console.log("notifications  => (:)",notifications);
        console.log("------notif-provider--------response -----------------------",response);
        
        if (
          response.message !== MessageImportantCheckedMessage ||
          (response.message === MessageImportantCheckedMessage && !notifications.some(n => n.conversation_code === response.conversation_code)))
          {
            setNotifications(prev => [...prev,response]);
          }
      };
      webSocket.onerror = (event: any) => console.log("error", event)
    }
  }, [webSocket]);
  return (
    <NotificationContext.Provider value={{ notifications, setNotifications }}>
      {children}
    </NotificationContext.Provider>
  );
};
export default NotificationContextProvider;
