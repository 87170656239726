import React from 'react'

export default function MessagesChatIcon() {
  return (
    <svg width="38" height="34" viewBox="0 0 38 34" fill="none" xmlns="http://www.w3.org/2000/svg" className='aion-messages-chat-icon'>
      <path d="M0.339966 13.16C0.469966 12.43 0.549965 11.68 0.739965 10.96C1.64997 7.52998 3.68997 4.89996 6.72997 3.12996C11.72 0.239963 16.8 0.219983 21.9 2.89998C22.01 2.95998 22.12 3.02998 22.31 3.14998C21.69 3.22998 21.16 3.26997 20.63 3.35997C15.68 4.21997 11.67 6.60997 9.09996 10.98C6.21996 15.86 6.30997 20.86 9.06997 25.8C9.12997 25.9 9.18997 26 9.24997 26.1C9.22997 26.11 9.20997 26.13 9.19997 26.12C8.64997 25.77 8.17996 26.02 7.66996 26.28C5.83996 27.24 3.98997 28.16 2.14997 29.09C1.42997 29.45 0.669966 29.14 0.519966 28.4C0.469966 28.17 0.519966 27.89 0.589966 27.66C1.22997 25.61 1.87996 23.56 2.54996 21.52C2.62996 21.28 2.60996 21.11 2.46996 20.89C1.30997 19.18 0.609966 17.3 0.409966 15.23C0.399966 15.14 0.369966 15.04 0.349966 14.95C0.339966 14.37 0.339966 13.76 0.339966 13.16Z" fill="white"/>
      <path d="M23.63 5.30005C28.07 5.44005 32.03 7.14003 34.96 10.91C38.19 15.07 38.35 20.8401 35.4 25.2101C35.24 25.4401 35.21 25.6301 35.3 25.9001C35.96 27.9401 36.61 29.99 37.26 32.04C37.49 32.77 37.21 33.42 36.54 33.55C36.26 33.6 35.91 33.5301 35.65 33.4001C33.67 32.4201 31.7 31.4101 29.74 30.4001C29.46 30.2501 29.23 30.25 28.93 30.36C23.55 32.3 18.51 31.6901 14 28.1201C10.7 25.5101 9.04997 22 9.22997 17.76C9.37997 14.28 10.86 11.3801 13.44 9.06006C16.24 6.54006 19.6 5.37005 23.63 5.30005ZM18.93 19.4701C19.52 19.4701 20.01 18.97 20.01 18.38C20.01 17.79 19.52 17.3 18.93 17.29C18.33 17.28 17.82 17.79 17.83 18.39C17.84 18.99 18.34 19.4701 18.93 19.4701ZM24.36 18.39C24.36 17.8 23.87 17.31 23.29 17.3C22.7 17.29 22.2 17.78 22.19 18.36C22.18 18.96 22.68 19.4701 23.28 19.4701C23.86 19.4701 24.36 18.97 24.36 18.39ZM28.71 18.3701C28.7 17.7801 28.21 17.29 27.62 17.3C27.03 17.3 26.54 17.8 26.54 18.38C26.54 18.98 27.05 19.4801 27.65 19.4701C28.24 19.4601 28.72 18.9501 28.71 18.3701Z" fill="white"/>
    </svg>
  )
}
